import React, { useEffect, useState } from 'react'

const MyService = () => {
    const userid = localStorage.getItem("uid");
    const [filteredData, setFilteredData] = useState([]);
    const [allData, setAllData] = useState([]);
    const fetchServicesData = async () => {
        const url = `https://mmq.mediquip.in/api/services/fetch/user/${userid}`;
    
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          });
    
          if (!response.ok) {
            console.log(response);
            throw new Error(`Error: ${response.status}`);
          }
          const data = await response.json();
          const filtered = data.filter(
            (item) => item.otherData && item.otherData.type === 'serviceProvider' && item.otherData.isActive === "true"
          );
          console.log( filtered) 
          setAllData(filtered); // Store all data
          setFilteredData(filtered); // Initially set to all data
          console.log(filteredData)
        } catch (error) {
          console.error('Error fetching services:', error);
        }
      };
    
      useEffect(() => {
        fetchServicesData();
      }, []);
  return (
    <div className=' w-full'>
      <div className="h-full overflow-x-auto w-full relative shadow-md sm:rounded-lg">
        <table className="h-full w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Name
              </th>
              <th scope="col" className="py-3 px-6">
                Specility
              </th>
              <th scope="col" className="py-3 px-6">
                Description
              </th>
              <th scope="col" className="py-3 px-6">
                Service Type
              </th>
              <th scope="col" className="py-3 px-6">
                Location
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <td className="py-4 px-6">{item.specialityOrProviderName.replace('undefined', '')}</td>
                  <td className="py-4 px-6">{item.otherData.data.specialtyName}</td>
                  <td className="py-4 px-6">{item.otherData.data.description}</td>
                  <td className="py-4 px-6">{item.serviceType}</td>
                  <td className="py-4 px-6">{item.otherData.data.address}</td>
                </tr>
              ))
            ) : (
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td colSpan="5" className="py-4 px-6 text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MyService