// Service Request Components
import React, { useEffect, useState } from "react";
import styles from "../../assets/css/services/service.module.css";
import { Catogories } from "../../components/Hero";
import ServiceForm from "./ServiceForm";
import SingleSelectDropdown from "./SingleSelectDropdown";
import axios from "axios";
import LeftPanel from "../../components/services/leftPanel";
import FilterPanel from "../../components/services/FilterPanel";

const fetchSellSpecilityList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/speciality/lists/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status_code === 200) {
      return data.data;
    }
  } catch (err) {
    //  setLoading(false)
    console.log("err", err);
  }
};

const getMenuList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/category/menulist/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status_code === 200) {
      return data.data;
    }
  } catch (err) {
    //  setLoading(false)
    console.log("err", err);
  }
};

const getUserDetail = async (uid, token) => {
  const formData = new FormData();
  formData.append("uid", uid);
  
  const response = await fetch("https://mmq.mediquip.in/api/v1/users/get_user_detail/", {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Token ${token}`,
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};
export const Service = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [formData, setFormData] = useState(null);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleTabClick = (value) => {
    setSelectedTab(value);
  };

  const [loading, setLoading] = useState(false);
  const [speciality, setSpeciality] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [menuDataTemp, setMenuDataTemp] = useState([]);
  const [equipmentData, setEquipMentData] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataTemp, setFilteredDataTemp] = useState([]);


  const fetchServicesData = async () => {
    const url = "https://mmq.mediquip.in/api/services/get/admin";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      console.log(response)
      if (!response.ok) {
        console.log(response);
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setServicesData(data); // Set the fetched data
      const filtered = data.filter(
        (item) => item.otherData && item.otherData.type === 'serviceProvider' && item.otherData.isActive === "true"
      );
      setFilteredData(filtered);
      setFilteredDataTemp(filtered)
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  // const filterDataByServiceType = (serviceType) => {
  //   setActiveServiceType(serviceType); // Set the current active service type
  //   const filtered = servicesData.filter(service => service.amount === serviceType);
  //   setFilteredData(filtered); // Set filtered data
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchSellSpecilityList();
        setSpeciality(response);
        const menuData = await getMenuList({parent:41});
        console.log("menuData", menuData);
        const filteredMenuData = menuData
          .filter(item => !["test1", "Testing", "adminTest"].includes(item.name))
          .map(item => {
            const { amount, ...rest } = item;
            return rest;
          });

        setMenuData(filteredMenuData);
        setMenuDataTemp(filteredMenuData);
        let filtered = [];
        menuData.forEach((parent) => {
          if (parent.children.length > 0) {
            parent.children.forEach((child) => {
              filtered.push(child);
            });
          }
        });
        console.log("filtered", filtered.length);
        setEquipMentData(filtered);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching speciality or menu data:", error);
      }
    };
    fetchData();
    fetchServicesData();
  }, []);
  const filterData = (data) => {
    console.log(data)
    const filtered = filteredData.filter((item) => {
      return (
        item.otherData &&
        item.otherData.data.equipmentName.toLowerCase() === data.toLowerCase()
      );
    });
    setFilteredData(filtered);
  };
  const handleSubmit = async (values) => {
    const buyerId = localStorage.getItem("uid");
    const token = localStorage.getItem("token");
    const userData = await getUserDetail(buyerId, token);
    const obj = {
      uid: buyerId,
      serviceType:selectedTab !== "Delivery" &&selectedTab !== "Shipping_and_Installation" ? selectedEquipment : values["Agency Name"], // From equipment dropdown
      specialityOrProviderName: selectedTab !== "Delivery" && selectedTab !== "Shipping_and_Installation" ? selectedSpeciality : values["Provider Name"], // From speciality dropdown
      description: values.Description,
      amount: selectedTab.replace(/_/g, " "),
      location2: values.Location,
      otherData: {type:"commanRequestService", userData:userData.data},
    };
    console.log(obj);

    setLoading(true);
    try {
      const response = await axios.post(
        "https://mmq.mediquip.in/api/services/add",
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      console.log("service data", response.data);
      setServicesData([...servicesData, response.data]);
      fetchServicesData();
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  // dddddddddddddddddd

  const serviceFormFields = {
    AMC: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    CMC: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    Delivery: [
      {
        label: "Agency Name",
        type: "text",
        placeholder: " Enter your Agency Name",
      },
      { label: "Provider Name", type: "text", placeholder: "Provider Name" },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Repairing: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Consumable: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Parts: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Shipping_and_Installation: [
      {
        label: "Agency Name",
        type: "text",
        placeholder: " Enter your Agency Name",
      },
      { label: "Provider Name", type: "text", placeholder: "Provider Name" },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Software_Update: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Rental_Services: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    // Add more services and their respective fields here...
  };

  const handleApplyClick = () => {
    if (selectedTab) {
      // const selectedFormData = serviceFormFields[selectedTab];
      // setFormData(selectedFormData);
      // openPopup();
      openPopup();
    } else {
      alert("Please select a service before applying.");
    }
  };

  const handleFilterChange = (updatedFilters) => {
    console.log('Selected Filters:', updatedFilters);
    // const filteredData = menuDataTemp.filter((item) =>
    //   updatedFilters.every((filter) => item.name.includes(filter.name))
    // );
    // console.log(filterData)
    //setFilteredMenuItem(filteredData);
    // setMenuData(filteredData);
    const filteredData = filteredDataTemp.filter((item) =>
      item.children?.every((child) =>
        updatedFilters.every((filter) => child.name === filter.name)
      )
    );
    if (filteredData.length > 0) {
      setFilteredData(filteredData);
    }
    // You can do something with the updatedFilters, e.g., apply filters to a list
  };

  const sfilters = [
    "AMC",
    "CMC",
    "Delivery",
    "Repairing",
    "Consumable",
    "Parts",
    "Shipping_and_Installation",
    "Software_Update",
    "Rental_Services",
  ];

  return (
    <section className={styles.serviceCont}>
      <div className={styles.serviceSubCont}>
        <div className={styles.serviceRow1}>
          <i className="bi bi-house-door"></i>
          <i style={{ fontSize: "14px" }} className="bi bi-chevron-right"></i>
          <span>Services</span>
        </div>

        <div className={styles.serviceRow2}>
          <div className={styles.servCat}>
            {/* <LeftPanel data= {filterData}/> */}
            <FilterPanel data={menuData} onFilterChange={handleFilterChange} />
            {/* <Catogories filterData={filterData} /> */}
          </div>
          <div>
            <div
              style={{ background: "none", boxShadow: "none", position: "relative" }}
              className={styles.serviceContent}
            >
              <div className=" absolute -top-10 " style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "24px",
            textAlign: "center",
            color: "#14b8a6",
          }}>
              Create Service
            </div>
              
              <div className={styles.serviceFilters}>
                {sfilters.map((value, index) => (
                  <span
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    key={index}
                    className={`${styles.sFilter} ${
                      selectedTab === value ? styles.selected : styles.unselect
                    }`}
                    onClick={() => handleTabClick(value)}
                  >
                    {value.replace(/_/g, " ")}
                  </span>
                ))}

                <span
                  onClick={handleApplyClick}
                  className={styles.sFilterApply}
                >
                  Apply
                </span>
              </div>
              <div className={styles.serviceData}></div>
            </div>

            <div
              className={styles.serviceTable}
              style={{ width: "100%", height: "33%", overflowY: "auto" }}
            >
              <table
                style={{
                  width: "100%",
                  marginTop: "10px",
                  borderCollapse: "collapse",
                  border: "1px solid #ddd",
                }}
              >
                <thead style={{ backgroundColor: "#f2f2f2" }}>
                  <tr
                    style={{
                      border: "1px solid #ddd",
                      borderCollapse: "collapse",
                    }}
                  >
                    <th style={{ padding: "8px", textAlign: "left" }}>Name</th>
                    <th style={{ padding: "8px", textAlign: "left" }}>
                      Specility
                    </th>
                    <th style={{ padding: "8px", textAlign: "left" }}>
                      Description
                    </th>
                    <th style={{ padding: "8px", textAlign: "left" }}>
                      Service Type
                    </th>
                    <th style={{ padding: "8px", textAlign: "left" }}>
                      Location
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                        border: "1px solid #ddd",
                        borderCollapse: "collapse",
                      }}
                    >
                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item.otherData.data.equipmentName}
                      {/* {item.specialityOrProviderName.replace("undefined", "")} */}
                        
                      </td>
                      <td style={{ padding: "8px", textAlign: "left" }}>
                      {item.serviceType}
                      </td>
                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item.description}
                      </td>
                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item.amount}
                      </td>
                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item.location2}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <ServiceForm
            onClose={closePopup}
            fields={serviceFormFields[selectedTab]}
            serviceName={selectedTab.replace(/_/g, " ")}
            handleClick={handleSubmit}
          />
        </div>
      )}
    </section>
  );
};
