import React, { useRef, useState, useEffect } from "react";
import styles from "../../assets/css/buy/checkout.module.css";
import {
  currLocat,
  activeLoc,
  video2img,
  location,
  decrease,
  incrase,
  paytam,
  upib,
} from "../../assets/images/index";
import { useFormik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import * as yup from "yup";
import {
  addressSchema,
  addressTypeSchema,
  citySchema,
  fnameSchema,
  mnumberSchema,
  nationalitySchema,
  pincodeSchema,
  stateSchema,
} from "../../utils/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postData, postData1 } from "../../services";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  handleAmcCms,
  handleHandling,
  handleShipping,
} from "../../app/Slices/UserData";
import ProdAddress from "../../utils/ProdAddress";
import { Loader } from "../../components/Loader";
const API_KEY = process.env.REACT_APP_ADDRESS_KEY;

export const Checkout = () => {
  const location = useLocation();
  let details = location?.state?.details;
  let insp_price = location?.state?.insp_price;
  let address = location?.state?.address;
  const userDetails = localStorage.getItem("userDetails");
  const [defaultAddress, setdefaultAddress] = useState("");
  const shipping = useSelector((state) => state.profileData.shipping_eq);
  const handling = useSelector((state) => state.profileData.handling_in);
  const amc = useSelector((state) => state.profileData.amc_cmc_service);
  const navigate = useNavigate();
  const [payStatus, setPayStatus] = useState("");
  const [toggle, setToggle] = useState(false);
  const [pricing, setPricing] = useState({ negotiable: 0, shipping: 0 });
  const prodAmt =
    parseFloat(pricing.negotiable) > 0
      ? pricing.negotiable
      : parseFloat(parseFloat(details?.asking_price).toFixed(2));
  const inspectionFees = parseFloat(
    parseFloat((10 * prodAmt) / 100).toFixed(2)
  );
  const platformFees = parseFloat(parseFloat((5 * prodAmt) / 100).toFixed(2));
  const totalWithoutGST =
    platformFees +
    inspectionFees +
    pricing?.shipping +
    parseFloat(parseFloat(details?.installation_price).toFixed(2));

  const gst = parseFloat(parseFloat((18 * totalWithoutGST) / 100).toFixed(2));
  const [book, setBook] = useState({
    name: "EquipMedy",
    author: "EquipMedy",
    img: `${process.env.PUBLIC_URL}/logo.png`,
    price: details?.inspection_price
      ? details?.inspection_price
      : Number(details?.asking_price) + platformFees,
  });

  useEffect(() => {
    (async () => {
      let data = {
        product: details?.uid,
        buyer: `${JSON.parse(userDetails)?.uid}`,
      };
      const res = await postData1(
        "product/order/get_order_shipping_price/",
        data,
        true
      );
      if (res?.status_code === 200) {
        pricing["negotiable"] = parseFloat(
          parseFloat(res?.data?.negotiable_price).toFixed(2)
        );
        pricing["shipping"] = parseFloat(
          parseFloat(res?.data?.shipping_price).toFixed(2)
        );
        setPricing({ ...pricing });
      }
    })();
  }, []);

  const initPayment = (data) => {
    const priceInCents = data.amount / 100;
    const options = {
      key: "rzp_live_FVI3GulOsYSVxA",
      amount: priceInCents,
      currency: data.currency,
      name: book.name,
      description: "Test Transaction",
      image: book.img,
      order_id: data.id,
      handler: function (response) {
        if (response.razorpay_payment_id) {
          let razorpay_order_id = response.razorpay_order_id;
          let razorpay_payment_id = response.razorpay_payment_id;
          let razorpay_signature = response.razorpay_signature;

          let item = {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
          };
          handlePaymentOrder(item);
          console.log(
            "Payment successful! Payment ID: " + response.razorpay_payment_id
          );
        } else {
          console.log("Payment failed! Error: " + response.error_description);
        }
      },
      prefill: {
        name: `${JSON.parse(userDetails)?.fName} ${
          JSON.parse(userDetails)?.lName
        }`,
        email: `${JSON.parse(userDetails)?.email}`,
        contact: `${JSON.parse(userDetails)?.number}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    const priceInCents = Math.round(parseFloat(book?.price) * 100);
    const data = {
      amount: priceInCents,
    };
    try {
      const res = await postData1(
        "product/order/razorpay_order_create/",
        data,
        true
      );
      if (res?.status) {
        initPayment(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentOrder = async (razorId) => {
    if (!details?.inspection_price) {
      setToggle(true);
      const data = {
        buyer: `${JSON.parse(userDetails)?.uid}`,
        seller: details?.user,
        product: details?.uid,
        asking_price: parseFloat(parseFloat(details?.asking_price).toFixed(2)),
        sub_total: details?.inspection_price
          ? Number(details?.asking_price) - Number(details?.inspection_price)
          : Number(details?.asking_price),
        // tax: gst,
        tax: 0,
        discount: 0,
        total: details?.inspection_price
          ? Number(details?.inspection_price)
          : `${(Number(details?.asking_price) + platformFees).toFixed(2)}`,
        remain_amount: details?.inspection_price
          ? Number(details?.asking_price) - Number(details?.inspection_price)
          : 0,
        payment_status: details?.inspection_price ? 2 : 3,
        order_status: details?.inspection_price ? 2 : 3,
        delivery_address: defaultAddress,
        shipping_equipment:
          details?.inspection_price && details?.post_type === 2
            ? false
            : details?.post_type === 2
            ? true
            : false,
        handling_instalation:
          details?.inspection_price && details?.post_type === 2
            ? false
            : details?.post_type === 2
            ? true
            : false,
        amc_services:
          details?.inspection_price && details?.post_type === 2
            ? false
            : details?.post_type === 2
            ? true
            : false,
        shipping_equipment_amount: 0,
        handling_instalation_amount: 0,
        amc_services_amount: 0,
        inspection_status: 0,
        payment_details: razorId,
      };
      const res = await postData1("product/order/add/", data, true);
      if (res?.status) {
        setToggle(false);
        navigate("/thanks/", {
          state: {
            msg: "Your payment is successfully complete, check your status on dashbaord.",
          },
        });
      }
    } else {
      handleAddInspection();
      navigate(
        `/products/${encodeURIComponent(details?.equip_name)}/?id=${
          details?.id
        }/`,
        {
          state: { prodDetails: details },
        }
      );
    }
  };

  const handleAddInspection = async (item) => {
    const formData = new FormData();
    formData.append("buyer", `${JSON.parse(userDetails)?.uid}`);
    formData.append("seller", details?.user);
    formData.append("product", details?.uid);
    formData.append(
      "amount",
      `${Number(details?.inspection_price).toFixed(2)}`
    );
    formData.append(
      "base_amount",
      `${Number(details?.asking_price).toFixed(2)}`
    );
    formData.append("inspection_file", "");
    const res = await postData("product/add_inspection/", formData, true);
  };

  const handletotalPayment = async (event) => {
    event.preventDefault();
    if (defaultAddress || details?.inspection_price) {
      const data = {
        buyer: `${JSON.parse(userDetails)?.uid}`,
        product: details?.uid,
        sub_total: details?.inspection_price
          ? Number(details?.inspection_price).toFixed(2)
          : Number(details?.asking_price).toFixed(2),
        total: details?.inspection_price
          ? Number(details?.inspection_price).toFixed(2)
          : `${(Number(details?.asking_price) + platformFees).toFixed(2)}`,
        remain_amount: details?.inspection_price
          ? Number(details?.asking_price).toFixed(2) -
            Number(details?.inspection_price).toFixed(2)
          : `${(Number(details?.asking_price) + platformFees).toFixed(2)}`,
        payment_type: payStatus,
        payment_status: details?.inspection_price ? 2 : 3,
        order_status: details?.inspection_price ? 2 : 3,
      };
      const res = await postData1("product/order/payemnt/", data, true);
      if (res?.status) {
        handlePayment();
      } else {
        toast.error(res?.msg);
      }
    } else {
      toast.error("Address Fields Required !");
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      {toggle && <Loader />}
      <div className={styles.checkoutCont}>
        <div className={styles.checkoutData}>
          <OrderSummary
            details={details}
            shipping={shipping}
            handling={handling}
            amc={amc}
            address={address}
          />
          <DelieveryAddress
            lat={details?.latitude}
            lng={details?.longitude}
            details={details}
            setdefaultAddress={setdefaultAddress}
          />
        </div>
        {!!!details?.inspection_price ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={styles.checkoutPriceData}>
              <p>TOTAL PRICING</p>
              <div>
                <span>Equipment Cost :</span>
                <span>₹{parseFloat(details?.asking_price).toFixed(2)}</span>
              </div>
              <div>
                <span>Negotiable Deal Price :</span>
                <span>₹{pricing?.negotiable}</span>
              </div>
              <div>
                <span>Platform Fee :</span>
                <span>₹{platformFees}</span>
              </div>
              <div>
                <span>Inspection & Verification Cost:</span>
                <span>{`${insp_price ? "-" : ""}₹${
                  insp_price ? insp_price : 0
                }`}</span>
              </div>
              <div>
                <span>Shipping Price :</span>
                <span>
                  ₹
                  {!shipping && !handling && !amc && details.post_type === 1
                    ? 0
                    : pricing?.shipping}
                </span>
              </div>
              <div>
                <span>Installation Price :</span>
                <span>₹{0}</span>
              </div>
              <div>
                <span>GST(18%):</span>
                <span>₹{0}</span>
              </div>
              <div>
                <b>Total Payment :</b>
                <b>
                  ₹
                  {insp_price
                    ? (Number(details?.asking_price) + platformFees).toFixed(
                        2
                      ) - Number(insp_price)
                    : (Number(details?.asking_price) + platformFees).toFixed(2)}
                </b>
              </div>
            </div>
            <div className={styles.paymentContainer}>
              <button onClick={handletotalPayment} className={styles.payBtn}>
                Make Payment
              </button>
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={styles.checkoutPriceData}>
              <p>TOTAL PRICING</p>
              <div>
                <b>Token Amount :</b>
                <b>₹{Number(details?.inspection_price).toFixed(2)}</b>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <p>
                  Note : If you want EquipMedy professional to carry out an
                  inspection <br /> before you purchase a REFUNDABLE amount to
                  be paid as a token amount.{" "}
                </p>
              </div>
              <div style={{ marginTop: "4rem" }}>
                <b>Total Payment :</b>
                <b>₹{Number(details?.inspection_price).toFixed(2)}</b>
              </div>
            </div>
            <div className={styles.paymentContainer}>
              <button onClick={handletotalPayment} className={styles.payBtn}>
                Pay Token Amount
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const CheckoutDataHead = (props) => {
  const defaultStyle = { backgroundColor: "#FFFFFF" };
  const selectedStyle = { backgroundColor: "#019C89" };
  const ref = useRef();

  const handleClick = (e) => {
    props.setShowBottom(!props.showBottom);
  };
  return (
    <div
      className={styles.checkoutDataHead}
      ref={ref}
      style={props.showBottom ? selectedStyle : defaultStyle}
      onClick={handleClick}
    >
      <div>
        <span className={styles.checkout1}>{props.seq}</span>
        <span
          className={styles.checkout2}
          style={{ color: props.showBottom ? "#FFFFFF" : "#878793" }}
        >
          {props.name}
        </span>
      </div>
      {!props.showBottom && <span className={styles.checkout3}>CHANGE</span>}
    </div>
  );
};

export const DelieveryAddress = ({
  lat,
  lng,
  details,
  profile,
  setAddressTab,
  setdefaultAddress,
}) => {
  const navigate = useNavigate();
  const [showAddress, setAddress] = useState(true);
  const [defaultCheck, setdefaultCheck] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [getLatLang, setLatLang] = useState({
    lat: null,
    lang: null,
  });
  const [isAddressUpdate, setAddressUpdate] = useState(false);
  const activeColor = { border: "1px solid #019C89", color: "#019C89" };

  useEffect(() => {
    if (details?.inspection_price) {
      setAddress(false);
    }
  }, [details?.inspection_price]);

  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_mobile: "",
      user_pincode: "",
      user_address: "",
      user_country: "",
      user_city: "",
      user_state: "",
      user_landmark: "",
      user_alternate_number: "",
      delivery_add: "",
    },
    validationSchema: yup.object().shape({
      user_name: fnameSchema,
      user_mobile: mnumberSchema,
      user_pincode: pincodeSchema,
      user_country: nationalitySchema,
      user_address: addressSchema,
      user_city: citySchema,
      user_state: stateSchema,
      delivery_add: addressTypeSchema,
    }),
    onSubmit: function (values) {
      handleSubmit(values);
    },
  });

  // Fetch city and state when pin code changes
  useEffect(() => {
    console.log(formik.values.user_pincode)
    const fetchLocation = async () => {
      if (formik?.values?.user_pincode?.length >= 6) {
        try {
          const response = await fetch(`https://api.zippopotam.us/in/${formik.values.user_pincode}`);
          if (response.ok) {
            const data = await response.json();
            const city = data.places[0]["place name"];
            const state = data.places[0]["state abbreviation"];
            const country = data.country;
            formik.setFieldValue("user_city", city);
            formik.setFieldValue("user_state", state);
            formik.setFieldValue("user_country", country);
          } else {
            toast.error("Pin-code is incorrect!");
            // Clear city and state if the response is not ok
            formik.setFieldValue("user_city", "");
            formik.setFieldValue("user_state", "");
          }
        } catch (error) {
          formik.setFieldValue("user_city", "");
          formik.setFieldValue("user_state", "");
        }
      } else {
        // Clear city and state if the pin code is not valid
        formik.setFieldValue("user_city", "");
        formik.setFieldValue("user_state", "");
      }
    };

    fetchLocation();
  }, [formik?.values?.user_pincode]);

  const handleSubmit = async (val) => {
    let data = {
      name: val?.user_name,
      mobile: val?.user_mobile,
      alternate_mobile: val?.user_alternate_number,
      address: val?.user_address,
      state: val?.user_state,
      city: val?.user_city,
      zip_code: val?.user_pincode,
      landmark: val?.user_landmark ? val?.user_landmark : "",
      latitude: lat,
      longitude: lng,
      location: val?.user_country,
      address_type: val?.delivery_add,
      is_default: 1,
    };

    if (isAddressUpdate) {
      Object.assign(data, { address_id: isAddressUpdate });
    }

    const res = await postData1("users/add_address/", data, true);

    if (res?.status) {
      toast.success("Address updated successfully !");
      if (setdefaultAddress) {
        setdefaultAddress(res?.data?.uid);
      }
    }
  };

  const handleReset = () => {
    formik.handleReset();
  };

  useEffect(() => {
    if (!getLatLang) {
      handleDefaultLocation();
    }
  }, [!getLatLang]);

  const handleDefaultLocation = () => {
    if (!profile) {
      setAddressUpdate(false);
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLang({
            lat: latitude,
            lang: longitude,
          });
          setdefaultCheck(false);
        },
        (error) => {
          console.log(error, "error getting location");
        }
      );
    } else {
      console.log("Gelocation is not available");
    }
  };

  useEffect(() => {
    const API_URL = `https://nominatim.openstreetmap.org/reverse?lat=${getLatLang?.lat}&lon=${getLatLang?.lang}&format=json&apiKey=${API_KEY}`;
    axios.get(API_URL).then((response) => {
        setdefaultCheck(false);
        formik.setValues({
          user_name: formik.values.user_name,
          user_mobile: formik.values.user_mobile,
          user_pincode: response?.data?.address?.postcode,
          user_country: response?.data?.address?.county,
          user_address: response?.data?.display_name,
          user_city: response?.data?.address?.state_district,
          user_state: response?.data?.address?.state,
          user_landmark: response?.data?.address.suburb,
          user_alternate_number: formik.values.user_alternate_number,
          delivery_add: formik.values.delivery_add,
        });
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  }, [getLatLang.lat, getLatLang.lang]);

  useEffect(() => {
    if (showAddress) {
      getBuyerLocation();
    }
  }, [showAddress]);

  const getBuyerLocation = async () => {
    let user = localStorage.getItem("uid");
    const res = await postData1(
      "users/address_list/",
      { user: user, is_default: 1 },
      true
    );
    if (res?.status) {
      setdefaultCheck(true);
      if (setdefaultAddress) {
        setdefaultAddress(res?.data[0]?.uid);
      }
      setAddressUpdate(res?.data[0]?.uid);

      formik.setValues({
        user_name: userDetails?.fName,
        user_mobile: userDetails?.number,
        user_pincode: res?.data[0]?.zip_code,
        user_country: res?.data[0]?.location,
        user_address: res?.data[0]?.address,
        user_city: res?.data[0]?.city,
        user_state: res?.data[0]?.state,
        user_landmark: res?.data[0]?.landmark,
        user_alternate_number: res?.data[0]?.alternate_mobile,
        delivery_add: res?.data[0]?.address_type,
      });
    } else {
      toast.error("Enter Your Current Address");
    }
  };

  const handleDefaultAddress = () => {
    if (!defaultCheck) {
      getBuyerLocation();
      setLatLang({});
      formik.handleReset();
    }
  };

  const handleBackBtn = () => {
    const encodedEquipName = encodeURIComponent(details?.equip_name);
    navigate(`/products/${encodedEquipName}/?id=${details?.id}/`, {
      state: { prodDetails: details },
    });
  };

  return (
    <>
      {!!!details?.inspection_price && (
        <div>
          {profile ? (
            ""
          ) : (
            <CheckoutDataHead
              seq="1"
              name="DELIVERY ADDRESS"
              setShowBottom={setAddress}
              showBottom={showAddress}
            />
          )}

          {showAddress && (
            <div
              className={styles.deliveryAdd}
              style={{
                border: profile
                  ? "1px solid rgba(192, 192, 192, 0.945)"
                  : "none",
              }}
            >
              <form
                className={styles.addressForm}
                onSubmit={formik.handleSubmit}
              >
                {details?.equip_name && (
                  <div
                    className={styles.arrow_part}
                    style={{ cursor: "pointer" }}
                    onClick={handleBackBtn}
                  >
                    <FaArrowLeft />{" "}
                    <span style={{ paddingLeft: "5px" }}>Back</span>
                  </div>
                )}
                {!details?.equip_name && (
                  <div
                    className={styles.arrow_part}
                    style={{ cursor: "pointer" }}
                    onClick={() => setAddressTab(false)}
                  >
                    <FaArrowLeft />{" "}
                    <span style={{ paddingLeft: "5px" }}>Back</span>
                  </div>
                )}

                <div className={styles.currLocat}>
                  <span
                    onClick={handleDefaultLocation}
                    className={styles.currentAdd}
                    style={!defaultCheck ? activeColor : {}}
                  >
                    <img
                      src={defaultCheck ? currLocat : activeLoc}
                      width="25px"
                      alt="..."
                    />

                    <span>Use my current location</span>
                  </span>

                  {profile ? (
                    ""
                  ) : (
                    <span
                      onClick={handleDefaultAddress}
                      className={styles.defaultAdd}
                      style={defaultCheck ? activeColor : {}}
                    >
                      <input
                        checked={defaultCheck}
                        type="radio"
                        name="detault_address"
                      />
                      <span htmlFor="detault_address">Default Address</span>
                    </span>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Pincode"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        formik.setFieldValue("user_pincode", value); // Store as string
                      }
                    }}
                    value={formik.values.user_pincode}
                    name="user_pincode"
                    style={{
                      border:
                        formik.touched.user_pincode &&
                        formik.errors.user_pincode
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                  <input
                    disabled
                    type="text"
                    placeholder="Locality"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_country}
                    name="user_country"
                    style={{
                      border:
                        formik.touched.user_country &&
                        formik.errors.user_country
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                </div>
                <div>
                  <input
                    disabled
                    type="text"
                    placeholder="City/District/Town"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_city}
                    name="user_city"
                    style={{
                      border:
                        formik.touched.user_city && formik.errors.user_city
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                  <input
                    disabled
                    type="text"
                    placeholder="State"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_state}
                    name="user_state"
                    style={{
                      border:
                        formik.touched.user_state && formik.errors.user_state
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_name}
                    name="user_name"
                    style={{
                      border:
                        formik.touched.user_name && formik.errors.user_name
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                  <input
                    type="number"
                    placeholder="10-digit mobile number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_mobile}
                    name="user_mobile"
                    style={{
                      border:
                        formik.touched.user_mobile && formik.errors.user_mobile
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Address (Area & Street)"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user_address}
                    name="user_address"
                    style={{
                      border:
                        formik.touched.user_address &&
                        formik.errors.user_address
                          ? "1px solid #f01e2c"
                          : "",
                    }}
                  ></textarea>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Landmark (Optional)"
                    onChange={formik.handleChange}
                    value={formik.values.user_landmark}
                    name="user_landmark"
                  />
                  <input
                    type="number"
                    placeholder="Alternate Phone (Optional)"
                    onChange={formik.handleChange}
                    value={formik.values.user_alternate_number}
                    name="user_alternate_number"
                  />
                </div>
                <div className={styles.addresTy}>
                  <p>Address Type</p>
                  <div className={styles.addRadio}>
                    <div>
                      <input
                        type="radio"
                        name="delivery_add"
                        value="1"
                        onChange={formik.handleChange}
                        checked={String(formik.values.delivery_add) === "1"}
                      />
                      <span>Home (All day delivery)</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="delivery_add"
                        value="2"
                        onChange={formik.handleChange}
                        checked={String(formik.values.delivery_add) === "2"}
                      />
                      <span>Work (Delivery between 10AM-5PM)</span>
                    </div>
                  </div>
                </div>
                {formik.touched.delivery_add && formik.errors.delivery_add ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.delivery_add}
                  </div>
                ) : null}
                <div className={styles.saveData}>
                  <input
                    type="submit"
                    value={
                      profile
                        ? `${isAddressUpdate ? "UPDATE" : "SAVE"}`
                        : `${
                            isAddressUpdate ? "UPDATE" : "SAVE"
                          } AND DELIVER HERE`
                    }
                  />
                  <span onClick={handleReset}>CANCEL</span>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const OrderSummary = ({ details, shipping, handling, amc, address }) => {
  const [showOrder, setOrder] = useState(true);

  useEffect(() => {
    if (details?.inspection_price) {
      setOrder(true);
    }
  }, [details?.inspection_price]);
  return (
    <React.Fragment>
      <div>
        <CheckoutDataHead
          seq={details?.inspection_price ? "1" : "2"}
          name={
            details?.inspection_price
              ? "Token amount Payment for Inspection"
              : "ORDER SUMMARY"
          }
          setShowBottom={setOrder}
          showBottom={showOrder}
        />
        {showOrder && (
          <div className={styles.deliveryAdd}>
            <OrderSummaryCard
              details={details}
              shipping={shipping}
              handling={handling}
              amc={amc}
              address={address}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const OrderSummaryCard = ({ details, shipping, handling, amc, address }) => {
  const navigate = useNavigate();
  const price = useSelector((state) => state.profileData.eqip_price_update);
  const dispatch = useDispatch();
  const [quant, setQuant] = useState(1);
  useEffect(() => {
    handlePrice();
  }, [quant]);

  const handlePrice = () => {
    let price = details?.asking_price;
    for (let i = quant; i <= quant; i++) {
      price = price * i;
    }
  };

  const handleBackBtn = () => {
    const encodedEquipName = encodeURIComponent(details?.equip_name);
    navigate(`/products/${encodedEquipName}/?id=${details?.id}/`, {
      state: { prodDetails: details },
    });
  };
  return (
    <div className={styles.orderCardCont}>
      <div>
        {!details?.inspection_price ? (
          <div className={styles.prodData}>
            <img
              src={
                details?.product_images?.length > 0
                  ? details?.product_images[0]?.product_images
                  : video2img
              }
              width="190px"
              height="165px"
              alt="..."
            />
            <div>
              <h4>{details?.equip_name}</h4>
              {details?.seller_firstname !== null &&
                details.seller_lastname !== null && (
                  <p style={{ marginBottom: "10px" }}>
                    Seller : {details?.seller_firstname}{" "}
                    {details.seller_lastname}
                  </p>
                )}
              {details?.address !== "null" && (
                <div className={styles.sumLoc}>
                  <img
                    src={location}
                    width="17px"
                    style={{ marginRight: "5px" }}
                    alt="..."
                  />
                  <span>{<ProdAddress address={details?.address} />}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "0px 5px 10px 5px", padding: "5px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "8px",
                cursor: "pointer",
              }}
              onClick={handleBackBtn}
            >
              <FaArrowLeft style={{ marginBottom: "2px" }} />{" "}
              <span style={{ paddingLeft: "5px" }}>Back</span>
            </div>
            <div className={styles.orderProd}>
              <img
                src={
                  details?.product_images?.length > 0
                    ? details?.product_images[0]?.product_images
                    : video2img
                }
                width="190px"
                height="165px"
                alt="..."
              />
              <div className={styles.orderProdSub}>
                <div className={styles.orderText}>
                  <h4>{details?.equip_name}</h4>
                  {details?.address !== "null" && (
                    <div className={styles.orderLoc}>
                      <img
                        src={location}
                        width="17px"
                        style={{ marginRight: "5px" }}
                        alt="..."
                      />
                      <span>{<ProdAddress address={details?.address} />}</span>
                    </div>
                  )}
                </div>
                {details?.seller_firstname !== null &&
                  details.seller_lastname !== null && (
                    <p style={{ marginBottom: "10px" }}>
                      Seller : {details?.seller_firstname}{" "}
                      {details.seller_lastname}
                    </p>
                  )}

                <p style={{ marginBottom: "10px" }}>{details?.description}</p>
                <h3 className={styles.orderAmt}>
                  Total Amount : {Number(details?.asking_price).toFixed(2)}{" "}
                </h3>
                <h3 className={styles.orderAmt}>
                  Token Amount : {Number(details?.inspection_price).toFixed(2)}{" "}
                </h3>
              </div>
            </div>
          </div>
        )}
      </div>
      {!details?.inspection_price && (
        <div className={styles.prodService}>
          <b>Services Added</b>
          <div>
            <input
              type="checkbox"
              checked={shipping}
              onClick={() => dispatch(handleShipping(!shipping))}
            />
            <span>Shipping Equipment</span>
          </div>
          <div>
            <input
              type="checkbox"
              checked={handling}
              onClick={() => dispatch(handleHandling(!handling))}
            />
            <span>Handling & Installation</span>
          </div>
          <div>
            <input
              type="checkbox"
              checked={amc}
              onClick={() => dispatch(handleAmcCms(!amc))}
            />
            <span>AMC & CME Service</span>
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentOptions = ({ setPayStatus, details }) => {
  const [payOption, setPayOption] = useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const navigate = useNavigate();

  const handlePaymentType = async () => {
    const data = {};
    const res = await postData1("master/payment_option/", data, true);
    setPaymentType(res?.data);
  };

  useEffect(() => {
    if (details?.inspection_price) {
      setPayOption(true);
    }
    handlePaymentType();
  }, [details?.inspection_price]);

  return (
    <div style={{ boxShadow: "none", padding: "0px" }}>
      <CheckoutDataHead
        seq={details?.inspection_price ? "2" : "3"}
        name="PAYMENT OPTIONS"
        setShowBottom={setPayOption}
        showBottom={payOption}
        setseq="3"
      />
      {payOption && (
        <div className={styles.payOptions}>
          {paymentType?.reverse().map((type) => {
            return (
              <PaymentOptCard
                setPayStatus={setPayStatus}
                data={{
                  id: type?.id,
                  name:
                    type.name === "EMI"
                      ? `${type.name} (Easily Installment)`
                      : type.name,
                  img: type?.id === 1 ? paytam : type?.id === 2 ? upib : "",
                  tag:
                    type?.id === 3
                      ? "Add and secure your card as per RBI guidelines"
                      : type?.id === 4
                      ? "This instrument has low success, use UPI or cards for better experience"
                      : "",
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const PaymentOptCard = (props) => {
  const handlePaymentOption = (id) => {
    props.setPayStatus(id);
  };
  return (
    <div className={styles.payCard}>
      <div>
        <input
          type="radio"
          name="pay"
          value={props.data.id}
          onChange={(e) => handlePaymentOption(e.target.value)}
        />
        {props.data?.img && (
          <img src={props.data?.img} height="11px" alt="paytme" />
        )}
        <span>{props.data.name}</span>
      </div>
      {props.data?.tag && <p>{props.data?.tag}</p>}
    </div>
  );
};

const PayLogin = (props) => {
  const [loginData, setLoginData] = useState(false);

  const formik = useFormik({
    initialValues: {
      luser: "",
      lmobile: "",
    },
    validationSchema: yup.object().shape({
      luser: fnameSchema,
      lmobile: mnumberSchema,
    }),
    onSubmit: function (values) {
      handleSubmit(values);
    },
  });

  const handleSubmit = (val) => {
    console.log(val, "formik");
  };

  return (
    <>
      {!!!props?.details?.inspection_price && (
        <div>
          <CheckoutDataHead
            seq="1"
            name="LOGIN"
            setShowBottom={setLoginData}
            showBottom={loginData}
          />
          {loginData && (
            <div className={styles.deliveryAdd}>
              <form
                className={styles.addressForm}
                onSubmit={formik.handleSubmit}
              >
                <div>
                  <input
                    type="text"
                    placeholder="Login Name"
                    value={formik.values.luser}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    name="luser"
                  />
                  <input
                    type="number"
                    placeholder="Login mobile number"
                    value={formik.values.lmobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    name="lmobile"
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {formik.touched.luser && formik.errors.luser ? (
                    <div style={{ color: "red" }}>{formik.errors.luser}</div>
                  ) : null}
                  {formik.touched.lmobile && formik.errors.lmobile ? (
                    <div style={{ color: "red" }}>{formik.errors.lmobile}</div>
                  ) : null}
                </div>

                <div className={styles.saveData}>
                  <input type="submit" value="CHANGED" />
                  {/* <span>CANCEL</span> */}
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </>
  );
};
