import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const SellServices = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]); // Store all fetched data
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  // Fetch services data
  const fetchServicesData = async () => {
    const url = 'https://mmq.mediquip.in/api/services/get/admin';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        console.log(response);
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      const filtered = data.filter(
        (item) => item.otherData && item.otherData.type === 'serviceProvider'
      );
      setAllData(filtered); // Store all data
      setFilteredData(filtered); // Initially set to all data
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    fetchServicesData();
  }, []);

  // Handle real-time search (onSearch)
  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    
    if (value === '') {
      setFilteredData(allData); // Reset to all data if search is cleared
    } else {
      const filtered = allData.filter((item) =>
        item.serviceType?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  // Handle submit search (onSubmit)
  const handleFilterSearch = () => {
    const filtered = allData.filter((item) =>
      item.specialtyName?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  };
  const curlUpdate = async (selectedStatus) => {
    try {
      const response = await fetch(`https://mmq.mediquip.in/api/services/update/${selectedIndex?.id}`, {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: selectedIndex?.uid,
          serviceType: selectedIndex?.serviceType,
          specialityOrProviderName: selectedIndex?.specialityOrProviderName,
          description: selectedIndex?.description,
          otherData: {type:"serviceProvider", isActive: selectedStatus, data: selectedIndex?.otherData?.data, userData: selectedIndex?.otherData?.userData, address: selectedIndex?.otherData?.address},
        }),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      console.log("service data", data);
      toast.success(`Your ${selectedIndex?.serviceType} Service updated successfully !`);
    } catch (error) {
      console.error('Error updating service:', error);
    }
  };
  const handleChangeStatus = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    setSelectedIndex((prev) => ({
      ...prev,
      otherData: {
        ...prev.otherData,
        isActive: selectedStatus === 'true',
      },
      
    }));
   
    curlUpdate(selectedStatus)
  };

  // Show popup on service click
  const onHandleShowPopup = (item, index) => {
    setSelectedIndex(item);
    setShowPopup(true);
  };

  return (
    <div>
      <div className="flex flex-row w-full">
        <div className="flex justify-center my-4">
          <input
            type="search"
            className="p-2 pl-10 w-full appearance-none rounded text-sm text-gray-700"
            placeholder="Search by service type"
            value={search}
            onChange={onChangeSearch} // Real-time search (onSearch)
          />
        </div>
        <div className="flex justify-center pl-2 my-4">
          <button
            className="bg-[#219d8e] hover:bg-[#219d8e] text-white font-bold py-2 px-4 rounded-lg"
            onClick={handleFilterSearch} // Submit search (onSubmit)
          >
            Search
          </button>
        </div>
      </div>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Service Provider</th>
              <th scope="col" className="px-6 py-3">Speciality</th>
              <th scope="col" className="px-6 py-3">Description</th>
              <th scope="col" className="px-6 py-3">Service Type</th>
              <th scope="col" className="px-6 py-3">Location</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={index}
                className="bg-gray-100 dark:bg-gray-700 border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-6 py-4">
                  {item.specialityOrProviderName.replace('undefined', '')}
                </td>
                <td className="px-6 py-4">{item.otherData.data.specialtyName}</td>
                <td className="px-6 py-4">{item.description}</td>
                <td className="px-6 py-4">{item.serviceType}</td>
                <td className="px-6 py-4">{item.otherData.data.address}</td>
                <td className="px-6 py-4">
                  <button onClick={() => onHandleShowPopup(item, index)}>
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center ">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75"
              onClick={() => setShowPopup(false)}
            ></div>
            <div className="bg-white p-4 relative rounded-lg mx-auto z-10">
              <div className="max-w-4xl mx-auto bg-white p-6">
                <div className="flex items-center mb-4">
                  <h2 className="text-4xl font-semibold text-gray-700">
                    {selectedIndex.specialityOrProviderName.replace(
                      'undefined',
                      ''
                    )}
                  </h2>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="font-medium text-gray-600">Service type</p>
                    <p className="text-gray-800">{selectedIndex.serviceType}</p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Specialities name</p>
                    <p className="text-gray-800">
                      {selectedIndex.otherData.data.specialtyName}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Contact No.</p>
                    <p className="text-blue-600 hover:underline">
                      {selectedIndex.otherData.data.phone}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Address</p>
                    <p className="text-gray-800">
                      {selectedIndex.otherData.data.address}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Email</p>
                    <p className="text-blue-600 hover:underline">
                      {selectedIndex.otherData.data.email}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Zip Code</p>
                    <p className="text-blue-600 hover:underline">
                      {selectedIndex.otherData.data.zip}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Status</p>
                    <p className={` text-xs  w-16 text-center rounded-full py-1 text-white hover:underline ${selectedIndex.otherData.isActive ? 'bg-green-400' : 'bg-red-400 '}`}>
                      {selectedIndex.otherData.isActive ? 'Active' : 'Pending'}
                    </p>
                  </div>
                  <div>
                    <p className="font-medium text-gray-600">Action</p>
                    <div className="relative inline-block w-full text-gray-700">
                      <select
                        className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline"
                        value={status} // Bind the dropdown value to the state
                        onChange={handleChangeStatus} // Handle dropdown change
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex absolute right-4 top-0 justify-center mt-4">
                <button
                  onClick={() => setShowPopup(false)}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellServices;
