import React, { useEffect, useState } from 'react'

const RequestedOrder = () => {
    const [servicesData, setServicesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const[selectedIndex, setSelectedIndex] = useState(null);

    const fetchServicesData = async () => {
        const url = "https://mmq.mediquip.in/api/services/get/admin";
      
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          });
      
          if (!response.ok) {
            console.log(response);
            throw new Error(`Error: ${response.status}`);
          }
          const data = await response.json();
          setServicesData(data); // Set the fetched data
          const filtered = data.filter(item => item.otherData &&item.otherData.type === "orderServiceRequest");
          setFilteredData(filtered); // Set the filtered data
          // Default filter to "AMC"
          console.log("requested orders", filteredData[filteredData.length - 1]);
        } catch (error) {
          console.error("Error fetching services:", error);
        }
      };
      

       useEffect(() => {
        fetchServicesData();
      }, []);

      const onHandleShowPopup = (item, index) => {
        console.log(item)
        setSelectedIndex(item)
        setShowPopup(true)
        console.log("clicked", index, item)
      }
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Contact No
            </th>
            <th scope="col" className="px-6 py-3">
              User Email
            </th>
            <th scope="col" className="px-6 py-3">
              Address
            </th>
            <th scope="col" className="px-6 py-3">
              Order Price
            </th>
            <th scope="col" className="px-6 py-3">
              Services
            </th>
           
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
            {filteredData.map((item, index) => (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="px-6 py-4">
                 {item.specialityOrProviderName &&item.specialityOrProviderName.replace("undefined", "")}
                </td>
                <td className="px-6 py-4">
                  {item.otherData.userData.data.mobile}
                </td>
                <td className="px-6 py-4">
                {item.otherData.userData.data.email} 
                </td>
                
                <td className="px-6 py-4">
                  Consultation
                </td>
                <td className="px-6 py-4">
                {item.amount}
                </td>
                <td className="px-6 py-4">
                  {Object.entries(item.serviceType).map(([key, value]) => (
                    <div className="flex items-center" key={key}>
                      <label className="inline-flex items-center">
                        <input type="checkbox" className="rounded-full h-4 w-4 text-blue-600 focus:ring-blue-500 focus:ring-offset-2 focus:ring-2 focus:ring-offset-gray-200"  checked={value} />
                        <span className="ml-2 text-sm">{key}</span>
                      </label>
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={()=>onHandleShowPopup(item, index)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          
        </tbody>
      </table>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center ">
          <div className="fixed inset-0  bg-gray-500 bg-opacity-75" onClick={() => setShowPopup(false)}></div>
          <div className="bg-white p-4 relative rounded-lg  mx-auto z-10">
          <div className="max-w-4xl mx-auto  bg-white  p-6">
      <div className="flex items-center mb-4">
        
        <h2 className="text-4xl font-semibold text-gray-700">{selectedIndex.specialityOrProviderName.replace("undefined", "")}</h2>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <p className="text-gray-600">Product Name: {selectedIndex.otherData.data.equip_name}</p>
          <p className="text-gray-600">Product askingprice: {selectedIndex.otherData.data.asking_price}</p>
          <p className="text-gray-600">Product Brand: {selectedIndex.otherData.data.brand}</p>
          <p className="text-gray-600 py-2">Product description: {selectedIndex.otherData.data.description}</p>
          <p className="text-gray-600">Product Model: {selectedIndex.otherData.data.model}</p>
          <p className="text-gray-600">Seller name: {selectedIndex.otherData.data.seller_firstname}</p>
        </div>
        <div className="flex flex-col">
          {/* <p className="text-gray-600">Product Description: {selectedIndex.productDescription}</p>
          <p className="text-gray-600">Product Image: {selectedIndex.productImage}</p>
          <p className="text-gray-600">Product Status: {selectedIndex.productStatus}</p> */}
        </div>
      </div>
      
    </div>
            <div className="flex absolute right-4 top-0 justify-center mt-4">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              </button>
              
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RequestedOrder