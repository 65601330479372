import React, { useState } from 'react';

// const data = [
//   {
//     id: 1044,
//     name: 'Diagnostic Laboratory & Imaging',
//     parent: 41,
//     children: [
//       { id: 1045, name: 'Aspirator/Suction Machine', parent: 1044, children: [] },
//       { id: 1046, name: 'Cautery machine', parent: 1044, children: [] },
//       { id: 1047, name: 'Hematology Analyzer / Cell counter', parent: 1044, children: [] },
//       { id: 1048, name: 'Patient monitor', parent: 1044, children: [] },
//       { id: 1049, name: 'Defibrillators', parent: 1044, children: [] },
//       { id: 1050, name: 'Ventilator', parent: 1044, children: [] },
//       { id: 1051, name: 'Infusion Pump', parent: 1044, children: [] },
//       { id: 1052, name: 'Syringe Pump', parent: 1044, children: [] },
//       { id: 1053, name: 'Surgical lights / OT Light', parent: 1044, children: [] },
//       { id: 1054, name: 'Surgical tables/ OT tables', parent: 1044, children: [] },
//       { id: 1055, name: 'Electrosurgical unit / Cautery machine', parent: 1044, children: [] },
//       { id: 1056, name: 'Suction apparatus / Suction machine', parent: 1044, children: [] },
//     ],
//   },
//   {
//     id: 1032,
//     name: 'Ophthalmic Equipment',
//     parent: 41,
//     children: [
//       { id: 1033, name: 'Slit Lamp', parent: 1032, children: [] },
//       { id: 1034, name: 'Microscope', parent: 1032, children: [] },
//       { id: 1035, name: 'Phaco Machine', parent: 1032, children: [] },
//       { id: 1036, name: 'Slit Lamp', parent: 1032, children: [] },
//       { id: 1037, name: 'Chair Unit', parent: 1032, children: [] },
//       { id: 1038, name: 'Biometry', parent: 1032, children: [] },
//       { id: 1039, name: 'Perimetry', parent: 1032, children: [] },
//       { id: 1040, name: 'Tonometer', parent: 1032, children: [] },
//       { id: 1041, name: 'OT Table', parent: 1032, children: [] },
//       { id: 1042, name: 'Autoclave', parent: 1032, children: [] },
//       { id: 1043, name: 'Auto Refractors', parent: 1032, children: [] },
//     ],
//   },
// ];

const LeftPanel = (props) => {
  const data = props.data;
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [openParent, setOpenParent] = useState(null); // To track the open parent

  const handleCheckboxChange = (id, name) => {
    if (selectedFilters.some((item) => item.id === id)) {
      setSelectedFilters(selectedFilters.filter((item) => item.id !== id));
    } else {
      setSelectedFilters([...selectedFilters, { id, name }]);
    }
  };

  const toggleParent = (parentId) => {
    if (openParent === parentId) {
      setOpenParent(null); // Close the currently open parent
    } else {
      setOpenParent(parentId); // Open the selected parent
    }
  };

  const renderChildren = (children) => {
    return children.map((child) => (
      <div key={child.id} className="ml-4">
        <label>
          <input
            type="checkbox"
            checked={selectedFilters.some((item) => item.id === child.id)}
            onChange={() => handleCheckboxChange(child.id, child.name)}
          />
          {child.name}
        </label>
      </div>
    ));
  };

  const renderParents = () => {
    return data.map((parent) => (
      <div key={parent.id}>
        <div className="flex items-center">
          <button onClick={() => toggleParent(parent.id)}>
            {openParent === parent.id ? '-' : '+'} {/* Toggle button */}
          </button>
          <label className="ml-2">
            <input
              type="checkbox"
              checked={selectedFilters.some((item) => item.id === parent.id)}
              onChange={() => handleCheckboxChange(parent.id, parent.name)}
            />
            {parent.name}
          </label>
        </div>
        {openParent === parent.id && renderChildren(parent.children)}
      </div>
    ));
  };

  return (
    <div>
      <h2>Selected Filters</h2>
      {selectedFilters.length > 0 ? (
        <ul>
          {selectedFilters.map((filter) => (
            <li key={filter.id}>{filter.name}</li>
          ))}
        </ul>
      ) : (
        <p>No filters selected</p>
      )}

      <h2>Filter Panel</h2>
      {renderParents()}
    </div>
  );
};

export default LeftPanel;
