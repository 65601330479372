import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useDebounce } from "@uidotdev/usehooks";
import { handleCreateRequirement } from "../app/Slices/UserData";

import styles from "../assets/css/navigation.module.css";
import { companyName } from "../assets/data/data";
import equipLogo from "../assets/images/EQUIPMEDY 1.png";
import {
  downIcon,
  searchIcon,
  profileIcon,
  actimg,
  arrLeft,
  Jaipur,
  location,
} from "../assets/images/index";

import { changeLocation, changeLoginStatus } from "../app/Slices/AuthSlice";
import { clearAddItems, fetchUserDetails } from "../app/Slices/UserData";
import { setCurrBuyStatus } from "../app/Slices/UserData";

import { CreateRequirement } from "../features/CreateReqirement";
import { postData } from "../services";
import useClickOutside from "../customHooks/useClickOutside";

export const Navigation = (props) => {
  const exploreType = props?.exploreType;
  const setExploreType = props?.setExploreType;
  const blogRef = props?.blogRef;
  const articleRef = props?.articleRef;
  const techUpdatesRef = props?.techUpdatesRef;
  const expertTipsRef = props?.expertTipsRef;
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("uid");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [token, userId]);

  const links = [
    // { name: "EQUIPMENT CATEGORY", path: "/" },
    { name: "Pre Owned Equipment", path: "/preowned-equip/" },
    { name: "New Equipment", path: "/new-equip/" },
    { name: "Spare & Accessories", path: "/spare-accessories/" },
    { name: "Services", path: "/services/" },
    { name: "Manufacturers and Distributors", path: "/manufacturers/" },
    { name: "Contact Us", path: "/contact/" },
  ];

  const postlinks = [
    // {name:"Equipment Category",path:"/specialization"},
    { name: "My Profile", path: "/profile/" },
    { name: "My ADS", path: "/profile/ads/" },
    { name: "Notification", path: "/profile/notifications/" },
    { name: "My Services", path: "/profile/myservices/" },
    { name: "Payment History", path: "/profile/paymen-history/" },
    // { name: "Awaiting Payment", path: "/" },
    // { name: "Subcriptions", path: "/" },
    { name: "My Orders", path: "/profile/order-list/" },
    { name: "Scheduled Meetings", path: "/profile/shedule-meeting-list/" },
  ];

  return (
    <div
      // style={{ background: "red!important", height: "10rem", width: "100%" }}
      id="navigationBlur"
    >
      <header className={styles.headContainer}>
        <NavLink to="/">
          <img
            className={styles.logo}
            src={equipLogo}
            // src={process.env.PUBLIC_URL + "/equipmedybd.png"}
            alt={companyName}
          />
        </NavLink>
        <Search placeholder="Find medical instrument.." />
        <div style={{ display: "flex", gap: "0.65rem" }}>
          <BuyBtn />
          <SellBtn />
        </div>
        <Explore
          blogRef={blogRef}
          articleRef={articleRef}
          techUpdatesRef={techUpdatesRef}
          expertTipsRef={expertTipsRef}
          exploreType={exploreType}
          setExploreType={setExploreType}
        />
        {/* <Speciality /> */}

        {token ? <ProfileDropDown /> : <LoginBtn />}
        <div className={styles.navigate_setup}>
          <AddToCart />
          <Humberger />
        </div>
      </header>
      <hr className={styles.nav_line} />
      {pathname.includes("/post/") ? (
        <Nav2 links={postlinks} />
      ) : (
        <Nav2 links={links} />
      )}
      <hr className={styles.nav_line} />
    </div>
  );
};

const Nav2 = (props) => {
  return (
    <div className={styles.Nav2Container}>
      <div className={styles.navlinks}>
        {props?.links?.map((values, index) => {
          return (
            <NavLink to={values?.path} style={activateLink} key={index}>
              {values.name}
            </NavLink>
          );
        })}
      </div>
      <div>
        <CreatBtn />
      </div>
    </div>
  );
};

// const Location = (props) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const handleLocation = (event) => {
//     event.preventDefault();
//     setIsOpen(!isOpen);
//   };
//   const ref = useRef();
//   useEffect(() => {
//     document.addEventListener("click", (e) => {
//       if (ref.current && !ref.current.contains(e.target)) {
//         setIsOpen(false);
//       }
//     });
//   }, []);
//   return (
//     <div>
//       <div
//         className={styles.location}
//         id="loc1"
//         onClick={handleLocation}
//         ref={ref}
//       >
//         <span>India</span>
//         <img src={downIcon} alt=">" />
//       </div>
//       {isOpen ? <LocationDropDown handleLocation={handleLocation} /> : ""}
//     </div>
//   );
// };

// const LocationDropDown = (props) => {
//   const locationCity = [
//     { name: "Delhi", bg: Jaipur },
//     { name: "Mumbai", bg: Jaipur },
//     { name: "Bengaluru", bg: Jaipur },
//     { name: "Pune", bg: Jaipur },
//     { name: "Gujarat", bg: Jaipur },
//     { name: "Chennai", bg: Jaipur },
//     { name: "Ahmedabad", bg: Jaipur },
//     { name: "Kolkata", bg: Jaipur },
//     { name: "Bihar", bg: Jaipur },
//   ];

//   return (
//     <div className={styles.locationCont}>
//       <div className={styles.selectLocHead}>
//         <a href="" onClick={props.handleLocation}>
//           <img className={styles.leftArrow} src={arrLeft} alt="..." />
//         </a>
//         <p>Please Select your Location</p>
//       </div>

//       <div className={styles.inputCity}>
//         <input type="text" placeholder="Enter Your City" />
//       </div>

//       <div className={styles.detectLoc}>
//         <a href="">
//           <img className={styles.locationPic} src={location} alt="..." />
//         </a>
//         <p>Detect my location</p>
//       </div>
//       <div className={styles.pupularCity}>
//         <p>Popular Cities</p>
//       </div>
//       <div className={styles.allCity}>
//         {locationCity.map((value, index) => {
//           return (
//             <NavLink to="/" key={index}>
//               <img className={styles.cityImage} src={value.bg} alt="..." />
//               <p className={styles.cityPicName}>{value.name}</p>
//             </NavLink>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export const Search = (props) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState(props?.categorySearch ?? "");
  const [categoriesList, setCategoriesList] = useState([]);
  const debouncedCategories = useDebounce(categories, 500);

  const handleClickOutSide = useCallback(() => {
    setCategories("");
  }, []);

  useClickOutside(ref, handleClickOutSide);

  const handleChange = useCallback((e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;

    if (regex.test(e.target.value)) {
      setCategories(e.target.value);
    } else {
      setCategories("");
    }
  }, []);

  const handleSubmit = useCallback((item) => {
    setCategories("");
    navigate(`/search/search-items/${encodeURIComponent(item)}/`, {
      state: { cat: item },
    });
  }, []);

  const handleSubmitCategory = useCallback(
    (event) => {
      event.preventDefault();
      setCategories("");
      navigate(`/search/search-items/${encodeURIComponent(categories)}/`, {
        state: { cat: categories },
      });
    },
    [categories]
  );

  const fetchCategoriesList = useCallback(async (categories) => {
    const formData = new FormData();
    formData.append("q", categories);

    try {
      const res = await postData("product/category/menulist/", formData);

      if (res?.data) {
        setCategoriesList(
          res.data.filter((d) => {
            return (
              d?.name !== "Other" &&
              d?.name?.toLowerCase().includes(categories?.toLowerCase())
            );
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setCategories(props?.categorySearch ?? "");
  }, [props?.categorySearch]);

  useEffect(() => {
    if (debouncedCategories) {
      fetchCategoriesList(debouncedCategories);
    }
  }, [debouncedCategories]);

  return (
    <div ref={ref}>
      <form
        className={styles.search}
        style={{ width: props.width, height: props.height, position: "relative" }}
      >
        <input
          type="text"
          value={categories}
          placeholder={props.placeholder}
          onChange={props?.handleChange ?? handleChange}
          onKeyDown={props?.handleKeyPress}
        />
        <input
          type="submit"
          value={props.buttonValue || "Search"}
          // onClick={(e) => e.preventDefault()}
          onClick={handleSubmitCategory}
          style={{
            position: "absolute",
            right: 5,
            cursor: "pointer",
            width: props.buttonWidth,
            height: props.buttonHeight,
            fontSize: props.buttonFontSize,
            transform: `rotate(${props.rotation || 0}deg)`,
          }}
        />
      </form>

      {/* {categories && categoriesList?.length > 0 && (
        <div
          className={
            categoriesList?.length > 1
              ? styles.mainHeaderSearch
              : styles.mainHeaderSearch1
          }
          style={{
            width: props.width,
            height: props.height,
            display: props.showSearch == true ? "none" : "",
          }}
        >
          {categoriesList?.map((category) => {
            return (
              <p
                key={category?.id}
                onClick={() => handleSubmit(category?.name)}
                className={styles.subDropDOwn}
              >
                {category?.name}
              </p>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

const BuyBtn = () => {
  return (
    <div>
      <NavLink  style={{ textDecoration: "none", backgroundColor: "" }} className={styles.SellBtn} to="/search/">
      {/* <NavLink className={styles.BuyBtn} to="/search/"> */}
        Buy
      </NavLink>
    </div>
  );
};

const SellBtn = () => {
  const navigate = useNavigate();
  const handlClick = useCallback((e) => {
    e.preventDefault();
    navigate("/post/");
  }, []);

  return (
    <React.Fragment>
      <div>
        <NavLink onClick={handlClick} style={{ textDecoration: "none", backgroundColor: "" }} className={styles.SellBtn} to="">
          Sell
          <ToastContainer />
        </NavLink>
      </div>
    </React.Fragment>
  );
};

const Explore = (props) => {
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const exploreType = props?.exploreType;
  const setExploreType = props?.setExploreType;
  const blogRef = props?.blogRef;
  const articleRef = props?.articleRef;
  const techUpdatesRef = props?.techUpdatesRef;
  const expertTipsRef = props?.expertTipsRef;

  const [isOpen, setIsOpen] = useState(false);

  const exploreLinks = [
    { title: "Blog", ref: blogRef },
    { title: "Articles", ref: articleRef },
    { title: "Tech Updates", ref: techUpdatesRef },
    { title: "Expert Tips", ref: expertTipsRef },
  ];

  const handleClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    if (location.pathname !== "/") {
      setIsOpen(false);
      navigate("/");
    }
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    });
  }, []);

  const handleNavLinkClick = (values) => {
    setIsOpen(false);
    if (values?.ref?.current) {
      setExploreType(values?.title);
      values?.ref?.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.warn("Ref is null:", ref);
    }
  };

  return (
    <div className={styles.exploreCont} ref={ref}>
      <div className={styles.exploreHead} onClick={handleClick}>
        <span>{exploreType ? exploreType : "Explore"}</span>
        <img src={downIcon} alt=">" />
      </div>
      {isOpen ? (
        <div className={styles.exploreLinks}>
          {exploreLinks.map((values, index) => {
            return (
              <NavLink
                key={index}
                onClick={() => handleNavLinkClick(values)}
                style={{ cursor: "pointer" }}
              >
                {values.title}
              </NavLink>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const Speciality = () => {
  return (
    <NavLink className={styles.Speciality} to="/speciality-search/">
      <img src={searchIcon} alt="search" />
      {/* <input  placeholder="Speciality" readOnly type="text" /> */}
      <p>Speciality</p>
    </NavLink>
  );
};

const LoginBtn = () => {
  const navigate = useNavigate();
  const handlClick = (e) => {
    e.preventDefault();
    navigate("/user/login/", { state: { navigateTo: "/" } });
  };
  return (
    <div>
      <NavLink className={styles.LoginBtn} onClick={handlClick}>
        <img src={profileIcon} alt="profile" />
        <span>Login</span>
      </NavLink>
    </div>
  );
};

const ProfileDropDown = () => {
  // const [mfStatus, setMfStatus] = useState(0);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  // useEffect(() => {
  //   (async () => {
  //     const res = await postData1("product/get_dist_user/", {}, true, false);
  //     if (res?.status_code == 200) {
  //       localStorage.setItem("MfDistStatus", res?.data?.status);
  //       setMfStatus(res?.data?.status);
  //     }
  //   })();
  // }, []);

  const [isOpen, setIsOpen] = useState(false);

  let profileLinks = [
    { title: "My Profile", path: "/profile/" },
    { title: "My ADS", path: "/profile/ads/" },
    { title: "Notification", path: "/profile/notifications/" },
    { title: "Payment History", path: "/profile/paymen-history" },
    { title: "My Orders", path: "/profile/order-list/" },
    { title: "Scheduled Meetings", path: "/profile/shedule-meeting-list/" },
  ];
  const firstPart = profileLinks.slice(0, 1);
  const secondPart = profileLinks.slice(1);

  if (userDetails?.type === 1 || userDetails?.type === 2) {
    let distributorLink = {
      title: "Distributor Dashboard",
      path: "/distributor/dashboard/",
    };
    profileLinks = [...firstPart, distributorLink, ...secondPart];
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    });
  }, []);

  return (
    <div className={styles.exploreCont} ref={ref}>
      <div className={styles.profileHead}>
        <div className={styles.notificationBell}>
          {" "}
          <Notifications />
        </div>
        <div  className=" flex flex-row items-center justify-center" onClick={handleClick}>
          <img
            src={
              userDetails?.profileImg ? userDetails?.profileImg : profileIcon
            }
            style={{ width: "45px", height: "45px", borderRadius: "50%" }}
            alt="Dashboard"
          />
          <img className=" -pl-4 mt-2" src={downIcon} alt=">" />
        </div>
      </div>
      {isOpen ? (
        <div className={styles.exploreLinks}>
          {profileLinks?.map((values, index) => {
            return (
              <NavLink to={values.path} onClick={handleClick} key={index}>
                {values.title}
              </NavLink>
            );
          })}
          <Logout setIsOpen={setIsOpen} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const Logout = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = (event) => {
    event.preventDefault();
    dispatch(clearAddItems());
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    localStorage.removeItem("utype");
    localStorage.removeItem("MfDistStatus");
    localStorage.removeItem("userDetails");
    dispatch(changeLoginStatus(false));
    dispatch(changeLocation(false));
    dispatch(setCurrBuyStatus({ curr: 0 }));
    // setIsOpen(false)
    navigate("/");
  };
  return (
    <NavLink path="/" onClick={logout}>
      Logout
    </NavLink>
  );
};

export const CreatBtn = () => {
  const dispatch = useDispatch();
  const { createRequirementModal } = useSelector((state) => state.profileData);
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(handleCreateRequirement(true));
  };
  return (
    <React.Fragment>
      <div>
        <NavLink
          className={styles.CreatBtn}
          onClick={handleClick}
          style={{ color: "#FFFFFF" }}
          to="/"
        >
          Create Your Requirement
        </NavLink>
      </div>
      {createRequirementModal && <CreateRequirement />}
    </React.Fragment>
  );
};

// Small Screen
const Humberger = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const handleHumberg = () => {
    setIsMobile(!isMobile);
  };
  const handleClickOutSide = () => {
    setIsMobile(true);
  };
  useClickOutside(ref, handleClickOutSide);
  const links = [
    { name: "Pre Owned Equipment", path: "/preowned-equip/" },
    { name: "New Equipmet", path: "/new-equip/" },
    { name: "Create Your Requirements", path: "/" },
    { name: "Services", path: "/services/" },
    { name: "Spare & Accessories", path: "/" },
    { name: "Manufactures & Distributors", path: "/manufacturers/" },
    { name: "About Us", path: "/aboutus/" },
    { name: "Contact Us", path: "/contact/" },
    { name: "Post Ad", path: "/post/" },
  ];

  return (
    <div ref={ref} className={styles.humberg} id="humberg">
      <span id="humIcon" style={{ cursor: "pointer" }} onClick={handleHumberg}>
        {isMobile ? (
          <i className="bi bi-list"></i>
        ) : (
          <i className="bi bi-x"></i>
        )}
      </span>
      {isMobile ? (
        ""
      ) : (
        <div className={styles.mobileMenu}>
          {links.map((value, index) => {
            if (index === 0) {
              return (
                <div>
                  <NavLink
                    onClick={(e) => {
                      e.preventDefault();
                      setIsMobile(true);
                      navigate(value.path);
                    }}
                    style={{ color: "#019C89" }}
                    className={styles.topMobileMenu}
                    key={index}
                  >
                    {value.name}
                  </NavLink>
                </div>
              );
            }
            return (
              <div>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    setIsMobile(true);
                    navigate(value.path);
                    if (value.name === "Create Your Requirements") {
                      setOpen(true);
                      setIsMobile(true);
                    }
                  }}
                  key={index}
                >
                  {value.name}
                </NavLink>
              </div>
            );
          })}

          <div style={{ position: "fixed", bottom: "100px" }}>
            <img style={{ height: "37px" }} src={equipLogo} alt="" />
          </div>
        </div>
      )}
      {isOpen && <CreateRequirement setOpen={setOpen} />}
    </div>
  );
};

const AddToCart = () => {
  const add_items = JSON.parse(localStorage.getItem("add_to_cart")) || [];

  const carts = useSelector((state) => state.profileData.add_item_length);
  const addItems = useSelector((state) => state.profileData.cartItems);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={styles.notificationMobile}>
        <Notifications />
      </div>

      <div className={styles.AddToCart} onClick={() => navigate("/cart/")}>
        <img src={actimg} alt="atc" />
        <span className="p-2.5 -mt-1">
          {addItems?.length > 0 ? addItems?.length : add_items?.length}
        </span>
      </div>
    </React.Fragment>
  );
};

const Notifications = () => {
  const navigate = useNavigate();
  const handlClick = (e) => {
    navigate("/profile/notifications/");
  };

  return (
    <span className={styles.notification} onClick={handlClick}>
      <span>0</span>
      <i className="bi bi-bell"></i>
    </span>
  );
};

// non components functions
const activateLink = ({ isActive }) => {
  return {
    color: isActive ? "#019C89" : "#757574",
  };
};
