import React, { useState } from 'react';

const SingleSelectDropdown = ({ data, type, onSelect }) => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <div style={styles.container}>
      <select
        value={selectedValue}
        onChange={handleSelectChange}
        style={styles.dropdown}
      >
        <option value=""> {type}</option>
        {data.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: '20px',
  },
  dropdown: {
    width: '100%',
    marginTop: '6px',
    borderWidth: '1px',
    borderColor: '#ccc',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px 16px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.20)',
  },
  errorText: {
    fontSize: '12px',
    color: 'red',
    marginTop: '5px',
  },
};

export default SingleSelectDropdown;

