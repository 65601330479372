import React, { useEffect, useState, useCallback } from "react";
import styles from "../../assets/css/aboutus/aboutus2.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { AboutHead } from "./Aboutus";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay } from "swiper/modules";
import { postData1 } from "../../services/index";
import * as yup from "yup";
import {
  fnameSchema,
  blogDeschema,
  emailSchema,
  mnumberSchema,
} from "../../utils/validation";
import { ToastContainer, toast } from "react-toastify";
import {
  WhyChooseText,
  reason1,
  reason2,
  reason3,
  reason4,
  reason5,
  reason6,
  reason7,
  connectProf,
} from "../../assets/images";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { CompanyTitle } from "../../assets/data/data";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const Reasons7 = () => {
  // &reg;
  const cardData = [
    {
      img: reason1,
      title: "Transparent Buying Process: ",
      desc: `${CompanyTitle}<sup>&reg;</sup> maintain a high level of transparency throughout the entire process. Buyers can schedule direct meetings with sellers and connect through video calls, ensuring that both parties are kept informed at every step of the buying process to provide a seamless experience.`,
    },
    {
      img: reason4,
      title: "Security & Trust",
      desc: `${CompanyTitle}<sup>&reg;</sup> prioritize the security of your transactions and personal information. Our secure payment gateway and data protection measures ensure a safe and trustworthy online marketplace experience.`,
    },
    {
      img: reason2,
      title: "Quality Assurance",
      desc: `${CompanyTitle}<sup>&reg;</sup> prioritize quality and safety. All listings undergo a rigorous vetting process to ensure that the equipment meets industry standards and regulations. Our qualified service engineers inspect and verify equipment and generate inspection report helping buyers to take a final decision. `,
    },
    {
      img: reason7,
      title: "Extensive Marketplace",
      desc: `${CompanyTitle}<sup>&reg;</sup> boasts an extensive catalog of medical equipment, ranging from state-of-the-art devices to cost-effective, pre-owned options. Whether you're a healthcare professional looking to upgrade your facility or a Supplier seeking to reach a broader audience, ${CompanyTitle} is your one-stop solution.`,
    },
    {
      img: reason6,
      title: "User Friendly Interface",
      desc: ` Our user-friendly platform is designed for ease of use, with powerful search filters and intuitive navigation. Whether you're a seasoned healthcare professional or a first-time seller/buyer, you'll find our platform accessible and efficient.`,
    },
    {
      img: reason5,
      title: "Sustainability",
      desc: `${CompanyTitle}<sup>&reg;</sup> is committed to reducing medical equipment waste and its impact on the environment. By promoting the resale and reuse of medical equipment, we contribute to a greener and more sustainable healthcare industry.`,
    },
    {
      img: reason3,
      title: "End to End Solution",
      desc: `${CompanyTitle}<sup>&reg;</sup> offer a complete solution catering to every need, from inspection and verification to connecting with sellers, shipping & handling, installation, consumables, equipment service, creating custom requirements, financing options, and comprehensive/annual maintenance plans`,
    },
    {
      img: reason2,
      title: "Thank You",
      desc: `Thank you for choosing ${CompanyTitle}.com as your trusted partner in the world of medical equipment to Sell / Buy Used and New Medical Equipment. Together, we can transform healthcare for the better. For inquiries or assistance, please reach out info@EquipMedy.com,  customer.support@EquipMedy.com `,
    },
  ];
  const [displayCards] = useState(cardData);
  const [swiper, setSwiper] = useState(null);
  const slidePrev = useCallback(() => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  }, [swiper]);

  const slideNext = useCallback(() => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  }, [swiper]);

  return (
    <>
      <b style={{ display: "block", textAlign: "center", color: "#5b8bd6" }}>
        7 Reasons to Choose {CompanyTitle}.com
      </b>
      <div className={styles.reason7Cont}>
        <button
          style={{
            display: window.innerWidth > 668 ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
          }}
          onClick={slidePrev}
        >
          <FaCaretLeft />
        </button>
        <div style={{ textAlign: window.innerWidth > 668 ? 'center' : "justify", }} className={styles.reason7Head}>
          <Swiper
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 3,
              },
              1510: {
                slidesPerView: 4,
              },
            }}
            spaceBetween={8}
            pagination={{
              clickable: true,
            }}
            onSwiper={setSwiper}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className={styles.RcardCont}
          >
            {displayCards.map((value, index) => {
              return (
                <SwiperSlide key={index}>
                  <ReasonCard data={value} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
       <div style={{display:"flex",justifyContent:"center",alignItems:"center", gap:"10px"}}>
       <button
          style={{
            display: window.innerWidth < 600 ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
          }}
          onClick={slidePrev}
        >
          <FaCaretLeft />
        </button>
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
          }}
          onClick={slideNext}
        >
          <FaCaretRight />
        </button>
       </div>
      </div>
    </>
  );
};

const ReasonCard = (props) => {
  return (
    <div className={styles.reasonCard}>
      <img src={props.data.img} alt="reason1" />
      <b>{props.data.title}</b>
      <p dangerouslySetInnerHTML={{ __html: props.data.desc }} />
    </div>
  );
};

export const NewsArticle = (props) => {
  const apiData = props?.data;
  const [blogs, setBlogs] = useState([]);
  const [pagination, setPagination] = useState({ currPage: 1, tPage: 0 });

  const pageStyle = {
    backgroundColor: "#0EB4A0",
    color: "#FFFFFF",
  };

  const updatePage = (e, page) => {
    if (page > pagination.tPage) {
      return;
    }
    setPagination({ ...pagination, currPage: page });
  };

  useEffect(() => {
    (async () => {
      let data = {
        _page: pagination.currPage,
        _limit: 9,
        is_deleted: 0,
        content_type: apiData?.type,
      };
      const res = await postData1("master/list_content/", data, false, false);
      if (res?.status_code === 200) {
        setBlogs(res?.data?.data);
        setPagination({ ...pagination, tPage: res?.data?.total_pages });
      }
    })();
  }, [pagination.currPage]);

  return (
    <div ref={props?.reference} className={styles.newsArticle} id={apiData?.id}>
      <AboutHead title={apiData?.title} />
      <div style={{  textAlign: window.innerWidth > 668 ? 'center' : "justify", padding: "10px", fontSize: "14px", color: "#6d6d6d" }} >
        <p style={{ display: "flex", alignContent: "center", justifyContent: "center",}}>
          We use only the best quality materials on the market in order to
          provide the best products to our patients.
        </p>
      </div>
      <div className={styles.newsCards}>
        {blogs?.map((value, index) => {
          return <NewsCard key={index} data={value} title={apiData?.title} />;
        })}
      </div>
      {/* <div className={styles.artPaginate}>
        {pagination.tPage > 1 ? (
          <>
            <span
              style={pagination.currPage === 1 ? pageStyle : {}}
              onClick={(e) => updatePage(e, 1)}
            >
              1
            </span>
            <span
              style={pagination.currPage === 2 ? pageStyle : {}}
              onClick={(e) => updatePage(e, 2)}
            >
              2
            </span>
            <span
              style={pagination.currPage > 2 ? pageStyle : {}}
              onClick={(e) => updatePage(e, pagination.currPage + 1)}
            >
              Next..
            </span>
          </>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
};

const NewsCard = ({ data, title }) => {
  const navigate = useNavigate();
  let imgURL = `${IMGURL}/mmq_apps/static/upload/content/${data?.image}`;

  const readMore = () => {
    navigate("/aboutDetail", { state: { item: data, title: title } });
    window.scrollTo(0, 0);
  };
  return (
    <div className={styles.newsCard} onClick={readMore}>
      <img src={imgURL} alt="..." />
      <span>{new Date(data?.created_date).toDateString()}</span>
      <b>{data?.name}</b>
      <p>Read More</p>
    </div>
  );
};

export const LetsConnect = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      Email: "",
      msg: "",
      Phone: "",
    },
    validationSchema: yup.object().shape({
      name: fnameSchema,
      msg: blogDeschema,
      Email: emailSchema,
      Phone: mnumberSchema,
    }),

    onSubmit: function (values, actions) {
      handleContact(values);
    },
  });

  const handleContact = async (values) => {
    let data = {
      name: values?.name,
      email: values?.Email,
      contact_no: values?.Phone,
      message: values?.msg,
    };
    const res = await postData1("contact/add/", data, false, false);
    if (res?.status_code === 200) {
      toast.success("Thanks for showing interest,we will contact you soon !");
      formik.handleReset();
    } else {
      toast.error("Something went wrong, try again !");
    }
  };

  return (
    <div className={styles.letConnectCont}>
      <ToastContainer />
      <img src={connectProf} alt="..." />
      <form className={styles.connectForm} onSubmit={formik.handleSubmit}>
        <p>Let’s Connect</p>
        <div className={styles.formInput}>
          <div className={styles.letFieldRow}>
            <input
              type="text"
              placeholder="Full Name"
              name="name"
              id="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {formik.errors.name && (
              <div className={styles.letError}>{formik.errors.name}</div>
            )}
          </div>
          <div className={styles.letFieldRow}>
            <input
              type="numer"
              placeholder="Phone number"
              name="Phone"
              onChange={formik.handleChange}
              value={formik.values.Phone}
            />
            {formik.errors.Phone && (
              <div className={styles.letError}>{formik.errors.Phone}</div>
            )}
          </div>
          <div className={styles.letFieldRow}>
            <input
              type="text"
              placeholder="Email"
              name="Email"
              onChange={formik.handleChange}
              value={formik.values.Email}
            />
            {formik.errors.Email && (
              <div className={styles.letError}>{formik.errors.Email}</div>
            )}
          </div>
        </div>
        <div className={styles.letFieldRow}>
          <textarea
            id="msg"
            placeholder="Your Message"
            name="msg"
            onChange={formik.handleChange}
            value={formik.values.msg}
          ></textarea>
          {formik.errors.msg && (
            <div className={styles.letError}>{formik.errors.msg}</div>
          )}
        </div>
        <input className={styles.letsubmit} type="submit" value="SUBMIT" />
      </form>
    </div>
  );
};
