import React, { useEffect, useState } from 'react';

const FilterPanel = ({ data, onFilterChange }) => {
  const [filterData, setFilteredData] = useState(data);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [openParent, setOpenParent] = useState(null); // To track the open parent
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false); // Mobile drawer state
  const [searchText, setSearchText] = useState('');

  const handleCheckboxChange = (id, name) => {
    let updatedFilters;
    if (selectedFilters.some((item) => item.id === id)) {
      updatedFilters = selectedFilters.filter((item) => item.id !== id);
    } else {
      updatedFilters = [...selectedFilters, { id, name }];
    }
    
    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters); // Call the passed method with updated filters
  };

  const toggleParent = (parentId) => {
    if (openParent === parentId) {
      setOpenParent(null); // Close the currently open parent
    } else {
      setOpenParent(parentId); // Open the selected parent
    }
  };

  const renderChildren = (children) => {
    return children.map((child) => (
      <div key={child.id} className="ml-4">
        <label className="block">
          <input
            type="checkbox"
            checked={selectedFilters.some((item) => item.id === child.id)}
            onChange={() => handleCheckboxChange(child.id, child.name)}
          />
          {child.name}
        </label>
      </div>
    ));
  };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  const renderParents = () => {
    return filterData.map((parent) => (
      <div key={parent.id} className="py-2 border-b border-gray-300">
        <div className="flex items-center justify-start">
          <button
            onClick={() => toggleParent(parent.id)}
            className="text-lg mr-2 bg-gray-200 hover:bg-gray-300 border-green-500 px-2  rounded-full font-semibold focus:outline-none"
          >
            {openParent === parent.id ? '-' : '+'} {/* Toggle button */}
          </button>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={selectedFilters.some((item) => item.id === parent.id)}
              onChange={() => handleCheckboxChange(parent.id, parent.name)}
              className="mr-2"
            />
            <span className="text-gray-700">{parent.name}</span>
          </label>
        </div>
        {openParent === parent.id && (
          <div className="pl-4 mt-2 border-t border-b border-gray-300">
            <ul className="list-none pt-1">
              {renderChildren(parent.children)}
            </ul>
          </div>
        )}
      </div>
    ));
  };
 
  const handleSearchBox = (text) => {
    console.log('handleSearchBox called' , text);
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase()) ||
      (item.children && item.children.some((child) => child.name.toLowerCase().includes(text.toLowerCase())))
    );
    setFilteredData(filtered);
  }

  return (
    <div className=' overflow-y-auto relative '>
      {/* <h2>Selected Filters</h2>
      {selectedFilters.length > 0 ? (
        <ul>
          {selectedFilters.map((filter) => (
            <li key={filter.id}>{filter.name}</li>
          ))}
        </ul>
      ) : (
        <p>No filters selected</p>
      )} */}
      
      <div className=" bg-gray-200 p-2 sticky top-0">
        <input
          type="text"
          value={searchText}
          onChange={(e) => {
            const text = e.target.value;
            setSearchText(text);
            // const filtered = data.filter((item) =>
            //   item.name.toLowerCase().includes(text.toLowerCase())
            // );
            handleSearchBox(text)
          }}
          placeholder="Search..."
          className="p-2 rounded w-full border"
        />
      </div>

      {/* Mobile Drawer */}
      <button
        onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
        className="bg-blue-500 text-white p-2 rounded mt-4 md:hidden"
      >
        {isMobileDrawerOpen ? 'Close Filters' : 'Open Filters'}
      </button>

      {/* Drawer/Modal for mobile */}
      {isMobileDrawerOpen && (
        <div className="fixed inset-0 bg-white overflow-y-auto overflow-hidden p-4 z-50 md:hidden">
          <button
            onClick={() => setIsMobileDrawerOpen(false)}
            className="bg-red-500 text-white p-2 rounded mb-4"
          >
            Close
          </button>
          <h2>Filter Panel</h2>
          {renderParents()}
        </div>
      )}

      {/* Show Filter Panel directly on larger screens */}
      <div className="hidden overflow-y-scroll overflow-hidden md:block">
        <h2>Filter Panel</h2>
        {renderParents()}
      </div>
    </div>
  );
};

export default FilterPanel;
