import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/css/buy/meeting.module.css";
import { BackgroundBlur, TextModel, MSGBox } from "../../utils/Popups";
import { Calander } from "../../utils/Calanders";
import { Modal } from "antd";
import {
  clearMeetingData,
  handleAmcCms,
  handleHandling,
  handleOnloadService,
  handleShipping,
} from "../../app/Slices/UserData";
import {
  meetingImg,
  scheduleBtn,
  meetSuccess,
  meetIssued,
} from "../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postData1 } from "../../services";
// import { toast } from "react-toastify";
import GenerateInspReport from "../../Pdfgenerator/GenerateInspReport";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";


export const ScheduleMeeting = (props) => {
  const buyerId = localStorage.getItem("uid");
  const [isSuccess, setSuccess] = useState(false);
  const [isMetScheduled, setMetScheduled] = useState(false);
  const [isInspected, setInpection] = useState(false);
  const [isMeetIssue, setMeetIssue] = useState(false);
  const [isBuyIssue, setBuyIssue] = useState(false);
  const [isBuyOption, setBuyOption] = useState(false);
  const [sheduleLis, setSheduleLis] = useState("");
  const [rescheduled, setRescheduled] = useState("");
  const [scheduleMeeting, setScheduleMeeting] = useState(false);
  const item = props?.data;

  const handleGetMeetingList = async () => {
    props.setMeeting(false);
    const data = {
      buyer_id: buyerId,
      product_id: item?.uid,
    };
    const res = await postData1("product/get_schedule_meeting/", data, true);
    if (res?.status) {
      setSheduleLis(res?.data[0]?.uid);
    } else {
      setScheduleMeeting(true);
    }
  };

  const handleInspection = (e) => {
    if (isMetScheduled) {
      setInpection(!isInspected);
    } else if (isInspected) {
      setInpection(false);
    } else {
      props.setMeeting(false);
    }
  };
  const handleBuyIssue = () => {
    let popup = false;
    if (isMetScheduled) {
      setBuyOption(!isBuyOption);
      popup = isBuyOption;
    } else if (isBuyOption) {
      setBuyOption(false);
      popup = true;
    } else {
      setBuyIssue(!isBuyIssue);
      popup = isBuyIssue;
    }
  };

  return (
    <React.Fragment>
      {/* schedule meeting modal start */}
      <ToastContainer />
      <Modal
        title="Meeting Scheduled"
        open={props.openMeeting}
        onOk={() => props.setMeeting(false)}
        onCancel={() => props.setMeeting(false)}
        centered
        footer={null}
      >
        <>
          <div className={styles.meetSchedule}>
            <img src={meetingImg} alt="..." />
            <b>Meeting Scheduler</b>
            <p>
              Schedule a online meeting with the seller to get equipment
              Information.
            </p>
            <img
              style={{ cursor: "pointer" }}
              alt="..."
              src={scheduleBtn}
              height="55px"
              name="meetSC"
              onClick={handleGetMeetingList}
            />
          </div>
        </>
      </Modal>
      {/* schedule meeting modal end */}

      {/* Buy Product 1st step*/}
      <Modal
        title="Ready to Checkout?"
        open={props.buyClick}
        onOk={() => props.setBuyClick(false)}
        onCancel={() => props.setBuyClick(false)}
        centered
        footer={null}
        style={{ maxWidth: window.innerWidth <= 786 ? "100%" : "22%" }}
      >
        <InspectionReport
          handleInspection={handleInspection}
          item={item}
          setBuyOption={setBuyOption}
          setInpection={setInpection}
          setBuyClick={props.setBuyClick}
          address={props?.address}
          setMeeting={props.setMeeting}
          insp={props?.insp}
        />
      </Modal>
      {/* Buy Product 1st step*/}

      {/* select add on services */}
      <Modal
        title="Select Add On Services"
        open={isBuyOption}
        onOk={() => setBuyOption(false)}
        onCancel={() => setBuyOption(false)}
        centered
        footer={null}
      >
        <SelectServices
          setBuyOption={setBuyOption}
          setMeeting={props.setMeeting}
          handleBuyIssue={handleBuyIssue}
          item={item}
          address={props.address}
          insp={props?.insp}
        />
      </Modal>
      {/* select add on services */}

      {/* don't need of isMeetIssue component */}
      {/* {isMeetIssue && (
        <MeetingIssue
          handleInspection={handleInspection}
          setInpection={setInpection}
          setMeetIssue={setMeetIssue}
        />
      )} */}
      {/* {isBuyIssue && (
        <BuyIssue
          setBuyIssue={setBuyIssue}
          handleBuyIssue={handleBuyIssue}
          setBuyOption={setBuyOption}
        />
      )} */}
      {/* {isBuyOption && (
        <SelectServices
          setMeeting={props.setMeeting}
          handleBuyIssue={handleBuyIssue}
          item={item}
          address={props.address}
          insp={props?.insp}
        />
      )} */}
      {/* metting sucess start */}
      <Modal
        title="Meeting Scheduled"
        open={isSuccess}
        onOk={() => setSuccess(false)}
        onCancel={() => setSuccess(false)}
        centered
        footer={null}
      >
        <MeetingSuccess
          setSuccess={setSuccess}
          isMetScheduled={isMetScheduled}
          details={item}
          setMeeting={props.setMeeting}
        />
      </Modal>
      {/* metting sucess start */}

      {/* metting already scheduled start */}
      <Modal
        open={sheduleLis !== ""}
        onOk={() => setSheduleLis("")}
        onCancel={() => setSheduleLis("")}
        centered
        footer={null}
      >
        <TextModel
          setRescheduled={setRescheduled}
          setSheduleLis={setSheduleLis}
          setMeeting={props.setMeeting}
          msg={"Your Meeting is already scheduled !"}
        />
      </Modal>
      {/* metting already scheduled end */}

      {/* show calender start */}
      <Modal
        title="Schedule meeting with seller"
        open={scheduleMeeting || rescheduled !== ""}
        onOk={() => {
          setScheduleMeeting(false);
          setRescheduled("");
        }}
        onCancel={() => {
          setScheduleMeeting(false);
          setRescheduled("");
        }}
        centered
        footer={null}
        style={{ minWidth: "550px" }}
      >
        <Calander
          setScheduleMeeting={setScheduleMeeting}
          setRescheduled={setRescheduled}
          setSuccess={setSuccess}
          success={isSuccess}
          setMetScheduled={setMetScheduled}
          openMeeting={props.setMeeting}
          item={item}
        />
      </Modal>
      {/* show calender end */}
    </React.Fragment>
  );
};

const MeetingSuccess = (props) => {
  const dispatch = useDispatch();
  const meetingDetails = useSelector(
    (state) => state.profileData.meeting_details
  );
  const buyer_id = localStorage.getItem("uid");
  const items = props?.details;
  const [terms, setTerms] = useState(true);
 console.log("byer id", buyer_id);
  const handleSheduleMeeting = async () => {
    setTerms(false);
    if (items?.user !== buyer_id) {
      const data = {
        buyer: buyer_id,
        seller: items?.user,
        product: items?.uid,
        title: meetingDetails.title,
        date: meetingDetails.date,
        start_time: meetingDetails.start_time,
        end_time: meetingDetails.end_time,
        duration: meetingDetails.duration,
        remind_me: meetingDetails.remind_me,
        link: "1",
        status: items?.status,
      };
      const res = await postData1("product/schedule_meeting/", data, true);
      if (res?.status) {
        setTerms(true);
        props.setSuccess(false);
        dispatch(clearMeetingData());
        toast.success("Meeting scheduled successfuly !");
        setTimeout(() => {
          props.setMeeting(false);
        }, 1000);
      }
    } else {
      props.setMeeting(false);
      toast.error("You can't shedule meeting with own prouduct");
      setTerms(true);
    }
  };
  return (
    <div className={styles.meetSuccessCont}>
      <img alt="..." src={meetSuccess} style={{ marginBottom: "10px" }} />
      <b style={{ marginBottom: "10px" }}>Meeting Scheduled</b>
      <p className={styles.meetNotify}>
        Meeting request is sent to the seller . You will receive the meeting
        link shortly on your registered number.
      </p>
      {terms ? (
        <span
          className={styles.submitBtn}
          name="meetCNF"
          onClick={handleSheduleMeeting}
        >
          CONTINUE
        </span>
      ) : (
        <span
          className={styles.submitBtn}
          style={{ backgroundColor: "#019c8938" }}
        >
          CONTINUE
        </span>
      )}
    </div>
  );
};

const InspectionReport = (props) => {
  const prodDetails = props?.item;
  const navigate = useNavigate();
  let insp_price =
    props?.insp !== undefined ? props?.insp[props?.insp?.length - 1] : 0;

  const handlePayment = async (e) => {
    props.setMeeting(false);
    const data = {
      product_id: prodDetails?.uid,
      order_type: "2",
    };
    const res = await postData1("product/order_review/", data, true);
    if (res?.status) {
      navigate(
        `/checkout/${encodeURIComponent(props?.item?.equip_name)}/?id=${
          props?.item?.id
        }/`,
        {
          state: { details: res?.data, address: props?.address },
        }
      );
      window.scrollTo(0, 0);
    }
  };

  const handleSkip = (e) => {
    if (prodDetails?.post_type === 2 || prodDetails?.post_type === 3) {
      navigate(
        `/checkout/${encodeURIComponent(prodDetails?.equip_name)}/?id=${
          prodDetails?.id
        }/`,
        {
          state: { details: prodDetails, insp_price: insp_price?.amount },
        }
      );
    } else {
      props.setBuyOption(true);
      props.setBuyClick(false);
    }
  };

  const handleDownloadInsp = (amount) => {
    GenerateInspReport(amount);
  };
  return (
    <div className="bg-white rounded-lg  p-2 w-full md:w-1/2 lg:w-full mx-auto">
      {/* <div className="text-center mb-6">
        <h2 className="text-2xl font-bold">Inspection Already Paid</h2>
        <p className="text-sm text-gray-600">
          You have already paid for inspection. If you want to proceed with
          payment, click on the button below.
        </p>
      </div> */}
      <div className="flex flex-col items-center justify-center space-y-4">
        {insp_price?.amount ? (
          <button
            onClick={() => handleDownloadInsp(insp_price?.amount)}
            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-full font-bold text-white shadow-md"
          >
            {`Token amount paid ${insp_price?.amount}`}
          </button>
        ) : (
          prodDetails?.post_type !== 2 && prodDetails?.post_type !== 3 && (
            <button
              onClick={handlePayment}
              className="bg-[#219d8e] hover:bg-green-600 px-6 py-2 rounded-full font-bold text-white shadow-md"
            >
              Get it inspected by our professionals
            </button>
          )
        )}
        <button
          onClick={handleSkip}
          className="bg-[#219d8e] text-white hover:bg-green-800 px-6 py-3 rounded-full font-bold shadow-md"
        >
          Proceed with payment
        </button>
      </div>
    </div>
  );
};

const MeetingIssue = (props) => {
  const handleContinue = () => {
    props.setMeetIssue(false);
    props.setInpection(true);
  };
  return (
    <div
      className={styles.meetSuccessCont}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "useClickOutsidelate(-50%, -50%)",
      }}
    >
      <div className={styles.successCross}>
        <i class="bi bi-x" onClick={props.handleInspection}></i>
      </div>
      <img alt="..." src={meetIssued} style={{ marginBottom: "10px" }} />
      <b style={{ marginBottom: "10px" }}>Meeting Issue</b>
      <p className={styles.meetNotify}>
        Do you want to continue without scheduling a meeting with the seller.
      </p>
      <span className={styles.submitBtn} onClick={handleContinue}>
        CONTINUE
      </span>
    </div>
  );
};
const BuyIssue = (props) => {
  const handleContinue = () => {
    props.setBuyIssue(false);
    props.setBuyOption(true);
  };
  return (
    <div
      className={styles.meetSuccessCont}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className={styles.successCross}>
        <i className="bi bi-x" onClick={props.handleBuyIssue}></i>
      </div>
      <img alt="..." src={meetIssued} style={{ marginBottom: "10px" }} />
      {/* <b style={{ marginBottom: "10px" }}>Issue</b> */}
      <p className={styles.meetNotify}>
        Do you want to buy without scheduling a meeting with the seller &
        without getting insepection report !
      </p>
      <span className={styles.submitBtn} onClick={handleContinue}>
        CONTINUE
      </span>
    </div>
  );
};

// select add on services
const SelectServices = (props) => {
  const shipping = useSelector((state) => state.profileData.shipping_eq);
  const handling = useSelector((state) => state.profileData.handling_in);
  const amc = useSelector((state) => state.profileData.amc_cmc_service);
  let insp_price =
    props?.insp !== undefined ? props?.insp[props?.insp?.length - 1] : 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prodDetails = props?.item;

  const getUserDetail = async (uid, token) => {
    const formData = new FormData();
    formData.append("uid", uid);
    
    const response = await fetch("https://mmq.mediquip.in/api/v1/users/get_user_detail/", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Token ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  };
  const handlePayment = async (e) => {
    const buyerId = localStorage.getItem("uid");
    const token = localStorage.getItem("token");
    e.preventDefault(); // Prevent form submission if necessary
  
    // First, create the order and navigate to the checkout
    const data = {
      product_id: prodDetails?.uid,
      order_type: "1", // Order type
    };
   
    try {
      // Post the data and get the response
      const res = await postData1("product/order_review/", data, true);
       console.log("dddddddddd",res.data)
      if (res.status_code === 200) {
       const userData = await getUserDetail(buyerId, token);

       console.log("user data",userData)
        // If the response is successful, navigate to the checkout page
        navigate(
          `/checkout/${encodeURIComponent(props?.item?.equip_name)}/?id=${props?.item?.id}/`,
          {
            state: {
              details: res?.data, // Order review details
              address: props?.address, // User's address
              insp_price: insp_price?.amount, // Inspection price or other cost
            },
          }
        );
         console.log(res.data)
         const obj = {
          uid: buyerId,
          serviceType:{shipping:shipping, handling:handling, amc:amc},
          specialityOrProviderName:userData.data.profile.first_name + " " + userData.data.profile.last_name,
          description: res.data.description,
          amount: res.data.asking_price,
          location2: userData.data.profile.location,
          otherData:{ type:"orderServiceRequest",data:res.data, userData}
        };
 console.log(obj)
        try {
          const response = await axios.post(
            "https://mmq.mediquip.in/api/services/add",
            obj,
            {
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
              },
            }
          );
          console.log("service data", response.data);
          toast.success("Service request added successfully !");
          // setServicesData([...servicesData, response.data]);
          // fetchServicesData();
          
        } catch (error) {
          console.error("error", error);
          
        }
  
        // Scroll to the top of the page after navigating to checkout
        window.scrollTo(0, 0);
  
        // Once checkout navigation is done, navigate to the "Thanks" page with the message
        navigate("/thanks", {
          state: {
            msg: `Your order request is submitted successfully. Proforma invoice and payment link is being shared with you. For assistance, please call on 8595903939.`,
          },
        });
      }
    } catch (error) {
      console.error("Order creation failed:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };
  
  useEffect(() => {
    dispatch(handleOnloadService());
  }, [dispatch]);
  
  return (
    <div className={styles.buyServiceCont}>
      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={shipping}
          onClick={() => dispatch(handleShipping(!shipping))}
        />
        <span>Shipping Equipment</span>
      </div>
      {shipping && (
        <div className={styles.serviceNote}>
          * The shipping cost will be based on actuals.
        </div>
      )}
      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={handling}
          onClick={() => dispatch(handleHandling(!handling))}
        />
        <span>Handling & Installation</span>
      </div>
      {handling && (
        <div className={styles.serviceNote}>
          * The Handling & Installation cost will be based on actuals.
        </div>
      )}

      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={amc}
          onClick={() => dispatch(handleAmcCms(!amc))}
        />
        <span>AMC/CMC Service</span>
      </div>
      {amc && (
        <div className={styles.serviceNote}>
          * The AMC/CMC Service cost will be based on actuals.
        </div>
      )}

      <p
        className={styles.submitBtn}
        onClick={handlePayment}
        id={styles.servCont}
      >
        CONTINUE
      </p>
    </div>
  );
};
