// Sell Service components
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../assets/css/postAdvt.module.css";
import {
  addImg,
  addVideos,
  removeImg,
  setType,
  removeVideo,
  setEquipmentName,
  setEquipSpecification,
  setManufacturingYear,
  setProdPrice,
  setCompatibleModels,
  clearProdAddData,
  setEquipCondition,
  setEquip_Location,
  fetchCategories,
  setCategories,
  fetchSpecialityName,
  setSpecality,
  setLatLong,
  filterSpec,
  filterCategories,
  setNewEquipName,
  selectOtherName,
  setFullAddAddress,
  setServiceType,
} from "../../app/Slices/ProdAddSlice";
import { FaPlusCircle } from "react-icons/fa";

import {
  ImageUpload,
  arrLeft,
  location,
  postDropdown,
  videoIcon,
  mFlowChart,
  // cancel,
} from "../../assets/images/index";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import axios from "axios";
import { postData, postData1 } from "../../services";
import useClickOutside from "../../customHooks/useClickOutside";
// import { Footer, Footer2 } from "../../components/Footer";
import { Loader } from "../../components/Loader";
import AddressModel from "../../utils/AddressModel";
import { BackgroundBlur, GetStarted } from "../../utils/Popups";
const API_KEY = process.env.REACT_APP_ADDRESS_KEY;

export const PostAdvt = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProdAddData());
  }, []);

  return (
    <div className={styles.postContainer}>
      <Outlet />
      {/* <Footer2 /> */}
    </div>
  );
};

export const SelectAdvtType = () => {
  const selectTypes = ["PRE OWNED", "NEW", "SPARE & ACCESSORIES", "SERVICES"];
  const [prevSelected, setPrevSelected] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = JSON.parse(localStorage.getItem("userDetails"))?.type;

  const changeColor = (event) => {
    if (prevSelected) {
      prevSelected.style.backgroundColor = "#FFFFFF";
      prevSelected.style.color = "#0B7D6F";
    }
    event.currentTarget.style.backgroundColor = "#019C89";
    event.currentTarget.style.color = "#FFFFFF";
    setPrevSelected(event.currentTarget);
    dispatch(setType({ selected: event.currentTarget.innerText }));
  };

  const selectStyle = {
    backgroundColor: "#019C89",
    color: "#FFFFFF",
  };

  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );

  const handleContinue = (event) => {
    event.preventDefault();
    if (!selectedPostType) {
      toast.info("Please select AD type", {
        autoClose: 2000,
        closeButton: false,
      });
    } else {
      if (selectedPostType === "SERVICES") {
        navigate("/post/service/");
      } else {
        if (userType !== undefined) {
          if (userType === 2 || userType === 1) {
            if (selectedPostType === "PRE OWNED") {
              toast.info(
                "Attention: Only new products can be added. Manufacturers and distributors are not permitted to add pre-owned products",
                { autoClose: 2000, closeButton: false }
              );
            } else {
              navigate("/post/media/");
            }
          } else {
            navigate("/post/media/");
          }
        } else {
          navigate("/post/media/");
        }
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.advtPostSeCont}>
        <div className={styles.advtPostMediqwork} style={styles}>
          <img src={mFlowChart} alt="..." className={styles.postFlowChart} />
        </div>
        <div className={styles.selectAdvtCont}>
          <h3>Post Your Adv</h3>
          <div className={styles.slectTypes}>
            {selectTypes?.map((value, index) => {
              return (
                <span
                  onClick={changeColor}
                  key={index}
                  style={selectedPostType === value ? selectStyle : {}}
                >
                  {value}
                </span>
              );
            })}
          </div>
          <NavLink onClick={handleContinue} className={styles.advtContinue}>
            Continue
          </NavLink>
        </div>
      </div>
    </>
  );
};

export const AddServiceType = () => {
  const token = localStorage.getItem("token");
  const buyerId = localStorage.getItem("uid");
  const navigate = useNavigate();
  const services = useSelector(
    (state) => state.addProd.prodAddData.selectServiceType
  );
  const dispatch = useDispatch();
  const sfilters = [
    "AMC",
    "CMC",
    "Delivery",
    "Repairing",
    "Consumable",
    "Parts",
    "Shipping and Installation",
    "Software Update",
    "Rental Services",
  ];

  const handleServiceType = (item) => {
    dispatch(setServiceType(item));
  };

  const handleContinue = (event) => {
    if (!token) {
      toast.error("User are not Login", {
        autoClose: 2000,
        closeButton: false,
      });
      navigate("/user/login/");
      return;
    }
    console.log(services);
    event.preventDefault();
    if (services) {
      navigate("/post/addservice/", { state: { serviceType: services } });
      window.scrollTo(0, 0);
    } else {
      toast.error("ServiceType is required !", {
        autoClose: 2000,
        closeButton: false,
      });
    }
  };
  const handleBack = () => {
    window.history.back(-1);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <span onClick={handleBack} className={styles.postBack}>
        <img src={arrLeft} alt="..." />
        <span>Back</span>
      </span>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h3 style={{ marginBottom: "10px", fontSize: "18px" }}>
          Select Service Type <span className={styles.mandt}>*</span>
        </h3>
        <div className={styles.serviceFilters}>
          {sfilters?.map((value, index) => {
            return (
              <span
                onClick={() => handleServiceType(value)}
                className={
                  services === value ? styles.serviceClick : styles.sFilter
                }
                key={index}
              >
                {value}
              </span>
            );
          })}

          {/* <span className={styles.sFilterApply}>Apply</span> */}
        </div>
        <button
          style={{ marginTop: "15px" }}
          onClick={handleContinue}
          // to={"/post/media/"}
          className={styles.advtContinue}
        >
          Continue
        </button>
      </div>
    </React.Fragment>
  );
};

export const AdvtMedia = () => {
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const selectedImages = useSelector(
    (state) => state.addProd.prodAddData.prodImgs
  );
  const selectedVideos = useSelector(
    (state) => state.addProd.prodAddData.prodVideos
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000;
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  }
  const handlImages = (event) => {
    const current = event.target;
    const files = Array.from(current.files);
    const imageFiles = [];
    const videoFiles = [];

    // Separate files into images and videos
    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        // Limit check for images
        if (imageFiles.length > 7) {
          toast.error("You can only upload a maximum of 7 images.");
          return;
        }
        imageFiles.push(file);
      } else if (file.type.startsWith("video/")) {
        // Limit check for videos
        if (videoFiles.length > 3) {
          toast.error("You can only upload a maximum of 3 videos.");
          return;
        }
        videoFiles.push(file);
      }
    });

    // Process image files
    imageFiles.forEach((file) => {
      const imageId = generateUniqueId();
      const imageUrl = URL.createObjectURL(file);
      dispatch(addImg({ imageId, imageUrl, file }));
    });
    // Process video files
    videoFiles.forEach((file) => {
      const videoId = generateUniqueId();
      const videoUrl = URL.createObjectURL(file);
      dispatch(addVideos({ videoId, videoUrl, file }));
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedImages?.length > 0) {
      navigate("/post/location/");
      window.scrollTo(0, 0);
    } else {
      toast.error("Image is required !", {
        autoClose: 2000,
        closeButton: false,
      });
    }
  };

  const handleImageRemove = (imageId) => {
    dispatch(removeImg(imageId));
  };
  const handleVideoRemove = (videoId) => {
    dispatch(removeVideo(videoId));
  };

  const handleBack = () => {
    window.history.back(-1);
    dispatch(clearProdAddData());
  };
  useEffect(() => {
    if (!selectedPostType) {
      navigate("/post/");
    }
  }, [selectedPostType]);

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <span onClick={handleBack} className={styles.postBack}>
          <img src={arrLeft} alt="..." />
          <span>Back</span>
        </span>
        {selectedPostType === "SPARE & ACCESSORIES" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "-15px",
            }}
          >
            <label htmlFor="spareparts" style={{ marginBottom: "5px" }}>
              SPARE & ACCESSORIES
            </label>
            <input
              type="text"
              style={{ padding: "10px", width: "30%" }}
              name="spare"
              id="spare"
              placeholder="Enter spare & accessories"
            />
          </div>
        )}

        <form
          className={styles.advtMediaCont}
          onSubmit={handleSubmit}
          action="upload_endpoint"
          method="POST"
          encType="multipart/form-data"
        >
          <div className={styles.advtAllMedie}>
            <div>
              <h4>
                {`You can upload up to 7 images for the ${
                  selectedPostType === "SPARE & ACCESSORIES"
                    ? "Accessories."
                    : "Product."
                }`}
                <span className={styles.mandt}>*</span>
              </h4>
              <div className={styles.advtImgs}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedImages?.map((value, index) => {
                    return (
                      <div
                        key={index}
                        style={{ margin: "10px", display: "flex" }}
                      >
                        <img src={value?.imageUrl} key={value?.imageId} />
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => handleImageRemove(value?.imageId)}
                        >
                          X
                        </p>
                      </div>
                    );
                  })}
                  <label style={{ margin: "5px" }} htmlFor="inputimg">
                    <input
                      maxLength={7}
                      type="file"
                      id="inputimg"
                      accept="image/*"
                      multiple
                      onChange={handlImages}
                      name="image"
                    />
                    <span style={{ fontSize: "22px" }}>
                      <FaPlusCircle />
                    </span>
                    {/* <img src={ImageUpload} alt="Upload" /> */}
                  </label>
                </div>
              </div>
            </div>

            <div>
              <h4>
                {`You can upload up to 3 videos for the ${
                  selectedPostType === "SPARE & ACCESSORIES"
                    ? "Accessories"
                    : "Product"
                } (optional)`}
              </h4>
              <div className={styles.advtImgs}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedVideos?.map((value, index) => {
                    return (
                      <div style={{ margin: "10px", display: "flex" }}>
                        <video key={value?.imageId}>
                          <source src={value?.imageUrl} type="video/mp4" />
                        </video>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleVideoRemove(value?.imageId)}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                  <label style={{ margin: "5px" }} htmlFor="inputVideo">
                    <input
                      maxLength={3}
                      type="file"
                      id="inputVideo"
                      multiple
                      accept="video/*"
                      onChange={handlImages}
                      name="video"
                    />
                    <span style={{ fontSize: "22px" }}>
                      <FaPlusCircle />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <input
            type="submit"
            className={styles.advtContinue}
            value="Continue"
          />
        </form>
      </React.Fragment>
    </>
  );
};

export const AdvtLocation = () => {
  const [searchName, setSearchName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  const userDetails = localStorage.getItem("userDetails");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const allData = useSelector((state) => state.addProd.prodAddData);
  const equipName = useSelector(
    (state) => state.addProd.prodAddData.Equip_name
  );

  const addressField = useSelector(
    (state) => state.addProd.prodAddData.addAddress
  );

  const newEqupName = useSelector(
    (state) => state.addProd.prodAddData.newEquip_name
  );

  const selectOther = useSelector(
    (state) => state.addProd.prodAddData.other_name
  );
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const filterCat = filterCategories?.filter((el) => el?.name !== "Other");

  const specialityName = useSelector(
    (state) => state.addProd.prodAddData.specialtiey_name
  );
  const getLatLang = useSelector((state) => state.addProd.prodAddData.location);
  const CompatibleModel = useSelector(
    (state) => state.addProd.prodAddData.Compatible_Models
  );
  const prodCondition = useSelector(
    (state) => state.addProd.prodAddData.prodCondition
  );

  useEffect(() => {
    handleFilterCate();
  }, [searchName]);

  const handleFilterCate = () => {
    let ar = [];
    categories?.map((el) => {
      el?.children?.filter((el) => {
        if (el?.name?.toLowerCase().includes(searchName?.toLowerCase())) {
          ar?.push(el);
        }
      });
    });
    setFilterCategories(ar);
  };

  useEffect(() => {
    dispatch(fetchSpecialityName());
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleProdCondition = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(setEquipCondition({ ...prodCondition, name, value }));
    } else {
      dispatch(setEquipCondition({ name, value: "" }));
    }
  };

  const handleModels = (event) => {
    const { value } = event.target;
    if (value.trim() !== "") {
      dispatch(setCompatibleModels(value));
    } else {
      dispatch(setCompatibleModels(""));
    }
  };

  const handleChange = (event) => {
    const newName = event.target.value;
    if (newName.trim() !== "") {
      setSearchName(newName);
    } else {
      setSearchName("");
    }
    if (equipName?.length > searchName?.length) {
      dispatch(setEquipmentName());
    }
  };

  const handleNewEquipName = (event) => {
    const newName = event.target.value;
    if (newName.trim() !== "") {
      dispatch(setNewEquipName(newName));
    } else {
      dispatch(setNewEquipName(""));
    }
  };

  const handleLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setLatLong({ name: "lat", value: latitude }));
          dispatch(setLatLong({ name: "lang", value: longitude }));
        },
        (error) => {
          console.log(error, "error getting location");
        }
      );
    } else {
      console.log("Gelocation is not available");
    }
  };

  useEffect(() => {
    const lat = getLatLang?.lat ? getLatLang?.lat : 28.6883631;
    const lang = getLatLang?.lang ? getLatLang?.lang : 77.297967;
    const API_URL = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lang}&format=json&apiKey=${API_KEY}`;
    axios
      .get(API_URL)
      .then((response) => {
        dispatch(
          setFullAddAddress({
            // number:
            //   userDetails !== null ? `${JSON.parse(userDetails)?.number}` : "",
            // email:
            //   userDetails !== null ? `${JSON.parse(userDetails)?.email}` : "",
            address: response?.data?.display_name,
            landmark: response?.data?.address.suburb,
            city: response?.data?.address?.state_district,
            State: response?.data?.address?.state,
            zip_code: response?.data?.address?.postcode,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  }, [getLatLang]);

  const filterSpecialityName = specialityName?.filter((el) => {
    return el.name !== "adminTest1" && el.name !== "admintTest2";
  });
  const dropSpec = {
    title: "Speciality",
    mandt: "*",
    placeholder: "Select the medical specialty",
    dataList: filterSpecialityName,
  };
  const filterCategoriesName = categories?.filter((el) => {
    return el.name !== "adminTest";
  });
  const dropCat = {
    title: "Category",
    mandt: "*",
    placeholder: "Select equipment categories",
    dataList: filterCategoriesName,
  };

  const handleAddressValidation = () => {
    if (
      addressField?.number === null ||
      addressField?.number === "" ||
      addressField?.number?.length > 10 ||
      addressField?.number?.length < 10 ||
      addressField?.address === null ||
      addressField?.address === "" ||
      addressField?.landmark === null ||
      addressField?.landmark === "" ||
      addressField?.landmark === undefined ||
      addressField?.city === null ||
      addressField?.city === "" ||
      addressField?.State === null ||
      addressField?.State === "" ||
      addressField?.zip_code === null ||
      addressField?.zip_code === "" ||
      addressField?.zip_code?.length > 6 ||
      addressField?.zip_code?.length < 6
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedPostType === "PRE OWNED") {
      if (
        (equipName || newEqupName) &&
        allData?.categories?.length > 0 &&
        allData?.specility?.length > 0 &&
        handleAddressValidation()
      ) {
        if (JSON.parse(userDetails)?.uid) {
          let data = {
            name: `${JSON.parse(userDetails)?.fName}${
              JSON.parse(userDetails)?.lName
            }`,
            mobile: addressField.number,
            alternate_mobile: `${JSON.parse(userDetails)?.number}`,
            address: addressField.address,
            state: addressField.State,
            city: addressField.city,
            zip_code: addressField.zip_code,
            landmark: addressField.landmark,
            latitude: getLatLang?.lat,
            longitude: getLatLang?.lang,
            location: "India",
            is_default: 0,
          };
          const res = await postData1("users/add_address/", data, true);
          if (res?.status) {
            dispatch(setEquip_Location(res?.data?.uid));
            navigate("/post/pricing/");
            window.scrollTo(0, 0);
          } else {
            toast.error("addresss", { autoClose: 2000, closeButton: false });
          }
        } else {
          navigate("/post/pricing/");
          window.scrollTo(0, 0);
        }
      } else {
        if (
          addressField?.zip_code?.length > 6 ||
          addressField?.zip_code?.length < 6
        ) {
          toast.error("Please Enter six digits postal code !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else if (
          addressField?.number?.length > 10 ||
          addressField?.number?.length < 10
        ) {
          toast.error("Please Enter 10 digits phone number !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else {
          toast.error("All fields are mandatory !", {
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
    } else if (selectedPostType === "NEW") {
      if (
        (equipName || newEqupName) &&
        allData?.categories?.length > 0 &&
        allData?.specility?.length > 0 &&
        handleAddressValidation()
      ) {
        if (JSON.parse(userDetails)?.uid) {
          let data = {
            name: `${JSON.parse(userDetails)?.fName}${
              JSON.parse(userDetails)?.lName
            }`,
            mobile: addressField.number,
            alternate_mobile: `${JSON.parse(userDetails)?.number}`,
            address: addressField.address,
            state: addressField.State,
            city: addressField.city,
            zip_code: addressField.zip_code,
            landmark: addressField.landmark,
            latitude: getLatLang?.lat,
            longitude: getLatLang?.lang,
            location: "India",
            is_default: 0,
          };
          const res = await postData1("users/add_address/", data, true);
          if (res?.status) {
            dispatch(setEquip_Location(res?.data?.uid));
            navigate("/post/specifications/");
            window.scrollTo(0, 0);
          } else {
            toast.error("addresss", { autoClose: 2000, closeButton: false });
          }
        } else {
          navigate("/post/specifications/");
          window.scrollTo(0, 0);
        }
      } else {
        if (
          addressField?.zip_code?.length > 6 ||
          addressField?.zip_code?.length < 6
        ) {
          toast.error("Please Enter six digits postal code !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else if (
          addressField?.number?.length > 10 ||
          addressField?.number?.length < 10
        ) {
          toast.error("Please Enter 10 digits phone number !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else {
          toast.error("All fields are mandatory !", {
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
    } else if (selectedPostType === "SPARE & ACCESSORIES") {
      if (
        (equipName || newEqupName) &&
        allData?.Compatible_Models &&
        allData?.specility?.length > 0 &&
        allData?.prodCondition?.prod_desc &&
        handleAddressValidation()
      ) {
        if (JSON.parse(userDetails)?.uid) {
          let data = {
            name: `${JSON.parse(userDetails)?.fName}${
              JSON.parse(userDetails)?.lName
            }`,
            mobile: addressField.number,
            alternate_mobile: `${JSON.parse(userDetails)?.number}`,
            address: addressField.address,
            state: addressField.State,
            city: addressField.city,
            zip_code: addressField.zip_code,
            landmark: addressField.landmark,
            latitude: getLatLang?.lat,
            longitude: getLatLang?.lang,
            location: "India",
            is_default: 0,
          };
          const res = await postData1("users/add_address/", data, true);
          if (res?.status) {
            dispatch(setEquip_Location(res?.data?.uid));
            navigate("/post/specifications/");
            window.scrollTo(0, 0);
          } else {
            toast.error("addresss", { autoClose: 2000, closeButton: false });
          }
        } else {
          navigate("/post/specifications/");
          window.scrollTo(0, 0);
        }
      } else {
        if (
          addressField?.zip_code?.length > 6 ||
          addressField?.zip_code?.length < 6
        ) {
          toast.error("Please Enter six digits postal code !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else if (
          addressField?.number?.length > 10 ||
          addressField?.number?.length < 10
        ) {
          toast.error("Please Enter 10 digits phone number !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else {
          toast.error("All fields are mandatory !", {
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
    } else {
      if (
        (equipName || newEqupName) &&
        allData?.categories?.length > 0 &&
        allData?.specility?.length > 0 &&
        handleAddressValidation()
      ) {
        if (JSON.parse(userDetails)?.uid) {
          let data = {
            name: `${JSON.parse(userDetails)?.fName}${
              JSON.parse(userDetails)?.lName
            }`,
            mobile: addressField.number,
            alternate_mobile: `${JSON.parse(userDetails)?.number}`,
            address: addressField.address,
            state: addressField.State,
            city: addressField.city,
            zip_code: addressField.zip_code,
            landmark: addressField.landmark,
            latitude: getLatLang?.lat,
            longitude: getLatLang?.lang,
            location: "India",
            is_default: 0,
          };
          const res = await postData1("users/add_address/", data, true);
          if (res?.status) {
            dispatch(setEquip_Location(res?.data?.uid));
            navigate("/post/specifications/");
            window.scrollTo(0, 0);
          } else {
            toast.error("addresss", { autoClose: 2000, closeButton: false });
          }
        } else {
          navigate("/post/specifications/");
          window.scrollTo(0, 0);
        }
      } else {
        if (
          addressField?.zip_code?.length > 6 ||
          addressField?.zip_code?.length < 6
        ) {
          toast.error("Please Enter six digits postal code !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else if (
          addressField?.number?.length > 10 ||
          addressField?.number?.length < 10
        ) {
          toast.error("Please Enter 10 digits phone number !", {
            autoClose: 2000,
            closeButton: false,
          });
        } else {
          toast.error("All fields are mandatory !", {
            autoClose: 2000,
            closeButton: false,
          });
        }
      }
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!selectedPostType) {
      navigate("/post/");
    }
  }, [selectedPostType]);

  const handleCatSearch = (name) => {
    dispatch(setEquipmentName(name));
    setSearchName("");
  };

  const handleOtherCheck = () => {
    dispatch(selectOtherName(true));
    if (!selectOther) {
      dispatch(setNewEquipName(null));
    }
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <NavLink to="/post/media/" className={styles.postBack}>
          <img src={arrLeft} alt="..." />
          <span>Back</span>
        </NavLink>

        <div className={styles.infoForm}>
          <form action="/action_page.php" onSubmit={handleSubmit}>
            <div className={styles.formFiledCont}>
              <div className={styles.labelCol}>
                <label htmlFor="Equip_name">
                  Equipment Name<span className={styles.mandt}>*</span>
                </label>
                <input
                  className={
                    searchName ? styles.forBotMarg : styles.forBotMarg1
                  }
                  type="text"
                  id="Equip_name"
                  placeholder="Equipment name"
                  name="Equip_name"
                  onChange={handleChange}
                  value={equipName ? equipName : searchName}
                  autoComplete="off"
                  disabled={selectOther === true}
                />
                {searchName && (
                  <div
                    className={
                      filterCat?.length > 5
                        ? styles.equipNameDrop
                        : filterCat?.length < 1
                        ? ""
                        : styles.equipNameDrop1
                    }
                  >
                    {filterCat?.length
                      ? filterCat?.map((el) => {
                          return (
                            <>
                              <p
                                onClick={() => {
                                  handleCatSearch(el?.name);
                                }}
                                className={styles.equipnameDropDown}
                              >
                                {el?.name}
                              </p>
                            </>
                          );
                        })
                      : !selectOther && (
                          <div style={{ marginTop: "20px" }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                justifyContent: "fl",
                              }}
                            >
                              <label>Other</label>
                              <input
                                type="checkbox"
                                name="other_name"
                                id="other_name"
                                onClick={handleOtherCheck}
                                checked={selectOther}
                              />
                            </span>
                          </div>
                        )}
                  </div>
                )}
                {selectOther && (
                  <div style={{ marginTop: "20px" }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "fl",
                      }}
                    >
                      <label>Other</label>
                      <input
                        type="checkbox"
                        name="other_name"
                        id="other_name"
                        onClick={handleOtherCheck}
                        checked={selectOther}
                      />
                    </span>
                    <div className={styles.specifyed2}>
                      <label>Please Specify Other</label>
                      <input
                        type="text"
                        id="newEquip_name"
                        placeholder="Enter New Equipment"
                        name="newEquip_name"
                        onChange={handleNewEquipName}
                        value={newEqupName}
                        autoComplete="off"
                        disabled={selectOther === false}
                      />
                    </div>
                  </div>
                )}
                {selectedPostType === "SPARE & ACCESSORIES" ? (
                  ""
                ) : (
                  <AdvtCategoriesDorpDown data={dropCat} />
                )}
                <AdvtSpecialityDorpDown data={dropSpec} />
                {selectedPostType === "SPARE & ACCESSORIES" ? (
                  <>
                    <div className={styles.prodComptaible}>
                      <label htmlFor="lname">
                        Compatible Models{" "}
                        <span className={styles.mandt}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Compatible_Models"
                        name="Compatible_Models"
                        value={CompatibleModel}
                        onChange={handleModels}
                        style={{ padding: "11px", borderRadius: "5px" }}
                      />
                    </div>
                    <div className={styles.prodComptaible}>
                      <label htmlFor="lname">
                        Product Details <span className={styles.mandt}>*</span>
                      </label>
                      <input
                        type="text"
                        id="prod_desc"
                        name="prod_desc"
                        value={prodCondition?.prod_desc}
                        onChange={handleProdCondition}
                        style={{ padding: "11px", borderRadius: "5px" }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                {/* {formik.errors.equipment_name && formik.touched.equipment_name && (<div style={{color : 'red'}}>{formik.errors.equipment_name}</div>)} */}
              </div>
              <div className={styles.addressField2}>
                {(() => {
                  return getAddProdScreen2(selectedPostType, handleLocation);
                })()}
              </div>
            </div>
            <div className={styles.submit_width_btn}>
              <input
                className={styles.contButt}
                type="submit"
                value="Continue"
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    </>
  );
};

export const AdvtCategoriesDorpDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const categoriesId = useSelector(
    (state) => state.addProd.prodAddData.categories
  );

  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilter] = useState([]);

  useEffect(() => {
    if (!selectedPostType) {
      navigate("/post/");
    }
  }, [selectedPostType]);

  const handleCategoriesName = (event, val) => {
    let updateVal = { id: val?.id, name: val?.name };
    if (event.target.checked) {
      dispatch(setCategories(updateVal));
    } else {
      dispatch(filterCategories(updateVal));
    }
  };

  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilter(fil);
  };

  const handleCancelBtn = (item) => {
    dispatch(filterCategories(item));
    setFilterName("");
  };
  return (
    <>
      <ToastContainer />
      <div
        className={styles.speciality}
        style={{ marginTop: "35px", marginBottom: "35px" }}
        ref={ref}
      >
        <div className={styles.specTag}>
          <p>
            {props.data.title}
            <span className={styles.mandt}>{props.data.mandt}</span>
            <span style={{ color: "red" }}></span>
          </p>
        </div>
        <div className={styles.selectEquipDiv} onClick={() => setShow(true)}>
          {!show ? (
            <p>{props.data.placeholder}</p>
          ) : (
            <input
              type="text"
              placeholder="search category"
              // className={styles.catInputField}
              style={{ border: "none", padding: "5px" }}
              onChange={(e) => setFilterName(e.target.value)}
            />
          )}
          <img className={styles.dropDownImage} src={postDropdown} alt="..." />
        </div>
        {show && (
          <div className={styles.checkBox}>
            {filterData?.length > 0
              ? filterData
                  .slice()
                  ?.sort((a, b) => a?.name.localeCompare(b?.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="categories"
                          value={value?.id}
                          checked={
                            categoriesId
                              ? categoriesId?.some((el) => el.id === value.id)
                              : false
                          }
                          name="categories"
                          onChange={(e) => handleCategoriesName(e, value)}
                        />
                        <label htmlFor="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })
              : props?.data?.dataList
                  ?.slice()
                  .sort((a, b) => a?.name.localeCompare(b?.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="categories"
                          value={value?.id}
                          checked={
                            categoriesId
                              ? categoriesId?.some((el) => el.id === value.id)
                              : false
                          }
                          name="categories"
                          onChange={(e) => handleCategoriesName(e, value)}
                        />
                        <label htmlFor="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })}
          </div>
        )}
        <div className={styles.catSelect}>
          {categoriesId?.map((el, index) => {
            return (
              <div className={styles.catSelectClose} key={index}>
                <p>
                  {el?.name}
                  <span onClick={() => handleCancelBtn(el)}>
                    <i class="bi bi-x"></i>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const AdvtSpecialityDorpDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const Speciality = useSelector(
    (state) => state.addProd.prodAddData.specility
  );
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilter] = useState([]);

  useEffect(() => {
    if (!selectedPostType) {
      navigate("/post/");
    }
  }, [selectedPostType]);
  const handleCategoriesName = (event) => {
    dispatch(setSpecality(event.target.value));
  };
  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilter(fil);
  };

  const handleCancelBtn = (item) => {
    dispatch(filterSpec(item));
    setFilterName("");
  };
  return (
    <>
      <ToastContainer />
      <div className={styles.speciality} ref={ref}>
        <div className={styles.specTag}>
          <p>
            {props.data.title}
            <span className={styles.mandt}>{props.data.mandt}</span>
          </p>
        </div>
        <div className={styles.selectEquipDiv} onClick={() => setShow(true)}>
          {!show ? (
            <p>{props.data.placeholder}</p>
          ) : (
            <input
              type="text"
              placeholder="search category"
              // className={styles.catInputField}
              style={{ padding: "5px", border: "none" }}
              onChange={(e) => setFilterName(e.target.value)}
            />
          )}
          <img className={styles.dropDownImage} src={postDropdown} alt="..." />
        </div>

        {show && (
          <div
            className={
              props?.data?.dataList?.length > 4
                ? styles.checkBox
                : styles.checkBox1
            }
          >
            {filterData?.length > 0
              ? filterData
                  ?.slice()
                  ?.sort((a, b) => a?.name.localeCompare(b?.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="specility"
                          value={value?.name}
                          checked={Speciality?.includes(value?.name)}
                          name="specility"
                          onChange={(e) => handleCategoriesName(e)}
                        />
                        <label htmlFor="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })
              : props?.data?.dataList
                  ?.slice()
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="specility"
                          value={value?.name}
                          checked={Speciality?.includes(value?.name)}
                          name="specility"
                          onChange={(e) => handleCategoriesName(e)}
                        />
                        <label htmlFor="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })}
          </div>
        )}
        <div className={styles.catSelect}>
          {Speciality?.map((el, index) => {
            return (
              <div key={index}>
                <p className={styles.catSelectClose}>
                  {el}
                  <span onClick={() => handleCancelBtn(el)}>
                    <i class="bi bi-x"></i>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const AdvtPrice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prodCondition = useSelector(
    (state) => state.addProd.prodAddData.prodCondition
  );
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );

  useEffect(() => {
    if (!selectedPostType) {
      navigate("/post/");
    }
  }, [selectedPostType]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const handleProdCondition = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(setEquipCondition({ ...prodCondition, name, value }));
    } else {
      dispatch(setEquipCondition({ name, value: "" }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      prodCondition?.condition &&
      prodCondition?.negotiable &&
      prodCondition?.price &&
      prodCondition?.prod_desc
    ) {
      navigate("/post/specifications/");
      window.scrollTo(0, 0);
    } else {
      toast.error("All fields are mandatory", {
        autoClose: 2000,
        closeButton: false,
      });
    }
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <NavLink to="/post/location/" className={styles.postBack}>
          <img src={arrLeft} alt="..." />
          <span>Back</span>
        </NavLink>

        <form className={styles.advtPriceCont} onSubmit={handleSubmit}>
          <h3 className={styles.title}>
            Equipment Condition<span className={styles.mandt}>*</span>
          </h3>
          <div className={styles.radios}>
            <div>
              <input
                type="radio"
                value="1"
                name="condition"
                checked={prodCondition?.condition === "1"}
                onChange={handleProdCondition}
              />
              <label className={styles.rdt}>Good</label>
            </div>
            <div>
              <input
                className={styles.rd}
                type="radio"
                value="2"
                name="condition"
                checked={prodCondition?.condition === "2"}
                onChange={handleProdCondition}
              />
              <label className={styles.rdt}>Excellent</label>
            </div>
            <div>
              <input
                className={styles.rd}
                type="radio"
                value="3"
                name="condition"
                checked={prodCondition?.condition === "3"}
                onChange={handleProdCondition}
              />
              <label className={styles.rdt}>As Good as New</label>
            </div>
          </div>
          <div className={styles.prodDiscr1}>
            <div className={styles.totalPrice}>
              <div className={styles.currSymbol}>
                <h3 className={styles.askprice}>
                  Asking Price <span className={styles.mandt}>*</span>
                </h3>
                <i className="bi bi-currency-rupee"></i>
                <input
                  className={styles.priceinput}
                  type="number"
                  step="0.01"
                  onKeyDown={blockInvalidChar}
                  id={styles.rupee}
                  value={
                    prodCondition?.price !== null ? prodCondition?.price : ""
                  }
                  onChange={handleProdCondition}
                  name="price"
                />
                <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                  Platform fee including GST*
                </p>
              </div>
              <div className={styles.currSymbol}>
                <h3 className={styles.askprice}>
                  Platform Fee*{" "}
                  <span className={styles.priceSpan}> (in INR*)</span>
                </h3>
                <i className="bi bi-currency-rupee"></i>
                <input
                  className={styles.priceinput}
                  type="number"
                  step="0.01"
                  disabled="true"
                  onKeyDown={blockInvalidChar}
                  id={styles.rupee}
                  value={
                    prodCondition?.price !== null
                      ? (Number(prodCondition?.price) * 5) / 100
                      : ""
                  }
                  onChange={handleProdCondition}
                  name="price"
                />
              </div>
              <div className={styles.currSymbol}>
                <h3 className={styles.askprice}>
                  Total Price*{" "}
                  <span className={styles.priceSpan}> (in INR*)</span>
                </h3>
                <i className="bi bi-currency-rupee"></i>
                <input
                  className={styles.priceinput}
                  type="number"
                  step="0.01"
                  disabled="true"
                  onKeyDown={blockInvalidChar}
                  id={styles.rupee}
                  value={
                    prodCondition?.price !== null
                      ? Number(prodCondition?.price) +
                        Number(prodCondition?.price * 5) / 100
                      : ""
                  }
                  onChange={handleProdCondition}
                  name="price"
                />
              </div>
            </div>

            <div className={styles.radios}>
              <div>
                <input
                  type="radio"
                  value="1"
                  name="negotiable"
                  onChange={handleProdCondition}
                  checked={prodCondition?.negotiable === "1"}
                />
                <label className={styles.rdt}>Negotiable</label>
              </div>
              <div>
                <input
                  className={styles.rd1}
                  type="radio"
                  value="2"
                  name="negotiable"
                  onChange={handleProdCondition}
                  checked={prodCondition?.negotiable === "2"}
                />
                <label className={styles.rdt}>Slightly Negotiable</label>
              </div>
              <div>
                <input
                  className={styles.rd1}
                  type="radio"
                  value="3"
                  name="negotiable"
                  onChange={handleProdCondition}
                  checked={prodCondition?.negotiable === "3"}
                />
                <label className={styles.rdt}>Non-Negotiable</label>
              </div>
            </div>
          </div>
          <div className={styles.prodDiscr}>
            <label className={styles.pdis}>
              Product Description <span className={styles.mandt}>*</span>
            </label>

            <textarea
              value={prodCondition?.prod_desc}
              className={styles.tetAr}
              typeof="textarea"
              name="prod_desc"
              placeholder="Product"
              onChange={handleProdCondition}
            />

            <input type="submit" className={styles.bttn} value="Continue" />
          </div>
        </form>
      </React.Fragment>
    </>
  );
};

export const AdvtProdData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const ref = useRef(null);
  const [isValid, setIsValid] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [price, setPrice] = useState("");
  const [loader, setLoader] = useState(false);
  const [getStart, setGetStart] = useState(false);
  const [isBlur, setBlur] = useState(false);

  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const ManufacturingYear = useSelector(
    (state) => state.addProd.prodAddData.purchase_year
  );
  const specifications = useSelector(
    (state) => state.addProd.prodAddData.specifications
  );
  const prodCondition = useSelector(
    (state) => state.addProd.prodAddData.prodCondition
  );
  const userId = localStorage.getItem("uid");
  const prodPrice = useSelector(
    (state) => state.addProd.prodAddData.Prod_price
  );
  console.log(prodPrice)
  const allData = useSelector((state) => state.addProd.prodAddData);
  console.log(allData?.prodCondition?.price);
  const handleClickOutSide = () => {
    setIsValid(false);
  };

  useClickOutside(ref, handleClickOutSide);

  const handleDateList = () => {
    let date = new Date();
    let currentDate = date.getFullYear();
    let lastDate = date.setFullYear(date.getFullYear() - 15);
    let lastUpdateDate = new Date(lastDate).getFullYear();
    let list = [];
    for (let i = lastUpdateDate; i <= currentDate; i++) {
      list.push(i);
    }
    setYearList(list);
  };

  useEffect(() => {
    handleDateList();
  }, []);

  useEffect(() => {
    if (!selectedPostType) {
      // navigate("/post/");
    }
  }, [selectedPostType]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(setEquipSpecification({ ...specifications, name, value }));
    } else {
      dispatch(setEquipSpecification({ name, value: "" }));
    }
  };

  const postTypeNewValidation = () => {
    if (
      allData?.prodCondition?.price === null ||
      allData?.prodCondition?.price === "" ||
      allData?.prodCondition?.negotiable === null ||
      allData?.prodCondition?.negotiable === ""
    ) {
      toast.error("All Fields are Required !", {
        autoClose: 2000,
        closeButton: false,
      });
      setFormDisabled(true);
      setLoader(true);
      setTimeout(() => {
        setFormDisabled(false);
        setLoader(false);
      }, 1000);
      return false;
    }
    return true;
  };

  const validateSpecifications = () => {
    const specificationsValues = Object.values(allData?.specifications);
    const isAnyEmptyField = specificationsValues.some((value) => !value);
    if (isAnyEmptyField) {
      toast.error("All Fields are Required !", {
        autoClose: 2000,
        closeButton: false,
      });
      setFormDisabled(true);
      setLoader(true);
      setTimeout(() => {
        setFormDisabled(false);
        setLoader(false);
      }, 1000);
      return false;
    }
    return true;
  };

  const yearValidation = () => {
    if (allData?.purchase_year === "" || allData?.purchase_year === null) {
      toast.error("All Fields are Required !", {
        autoClose: 2000,
        closeButton: false,
      });
      setFormDisabled(true);
      setLoader(true);
      setTimeout(() => {
        setFormDisabled(false);
        setLoader(false);
      }, 1000);
      return false;
    }
    return true;
  };

  const spareAndAss = () => {
    if (
      allData.Prod_price === null ||
      allData.Prod_price === "" ||
      allData.specifications.brand === null ||
      allData.specifications.model === null ||
      allData.specifications.other_details === null ||
      allData.specifications.brand === "" ||
      allData.specifications.model === "" ||
      allData.specifications.other_details === ""
    ) {
      toast.error("All Fields are Required !", {
        autoClose: 2000,
        closeButton: false,
      });
      setFormDisabled(true);
      setLoader(true);
      setTimeout(() => {
        setFormDisabled(false);
        setLoader(false);
      }, 1000);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    console.log("ddd", allData?.prodCondition)
    event.preventDefault();
    setLoader(true);
    setFormDisabled(true);

    if (selectedPostType === "PRE OWNED") {
      if (!validateSpecifications() || !yearValidation()) {
        return;
      }
    }
    if (selectedPostType === "NEW") {
      if (!validateSpecifications() || !postTypeNewValidation()) {
        return;
      }
    }

    if (selectedPostType === "SERVICES") {
      if (!validateSpecifications()) {
        return;
      }
    }

    if (selectedPostType === "SPARE & ACCESSORIES") {
      if (!spareAndAss()) {
        return;
      }
    }
    if (
      allData?.Equip_location !== null ||
      "" ||
      selectedPostType === "SERVICES" ||
      selectedPostType === "SPARE & ACCESSORIES"
    ) {
      let images = allData?.prodImgs?.map((el) => el?.file);
      let videos = allData?.prodVideos?.map((el) => el?.file);

      const formData = new FormData();
      formData.append(
        "post_type",
        selectedPostType === "PRE OWNED"
          ? 1
          : selectedPostType === "NEW"
          ? 2
          : selectedPostType === "SPARE & ACCESSORIES"
          ? 3
          : selectedPostType === "SERVICES"
          ? 4
          : ""
      );
      images.forEach((image) => {
        formData.append("images", image);
      });
      videos.forEach((video) => {
        formData.append("videos", video);
      });
      formData.append(
        "equip_name",
        allData?.other_name ? allData?.newEquip_name : allData?.Equip_name
      );
      formData.append("address", allData?.Equip_location);
      formData.append(
        "category_list",
        allData?.categories?.map((el) => el?.id)
      );
      formData.append(
        "speciality_name",
        allData?.specility?.map((el) => (el ? el : "other"))
      );
      formData.append(
        "equip_condition",
        allData?.prodCondition?.condition
          ? allData?.prodCondition?.condition
          : ""
      );
      formData.append(
        "negotiable_type",
        allData?.prodCondition?.negotiable
          ? allData?.prodCondition?.negotiable
          : ""
      );
      formData.append(
        "asking_price",
        allData?.prodCondition?.price
          ? `${Math.ceil(
              Number(prodCondition?.price) +
                Number(prodCondition?.price * 5) / 100
            )}`
          :  prodPrice? `${Math.ceil(
              Number(prodPrice) +
                Number(prodPrice * 5) / 100
            )}`: ""
      );
      formData.append("description", allData?.prodCondition?.prod_desc);
      formData.append(
        "year",
        allData?.purchase_year ? allData?.purchase_year : ""
      );

      formData.append("brand", allData?.specifications?.brand);
      formData.append("model", allData?.specifications?.model);
      formData.append(
        "warranty",
        allData?.specifications?.waranty ? allData?.specifications?.waranty : ""
      );
      formData.append(
        "existing_amc",
        allData?.specifications?.amc_cme ? allData?.specifications?.amc_cme : ""
      );
      formData.append("other_details", allData?.specifications?.other_details);
      formData.append("latitude", allData?.location?.lat);
      formData.append("longitude", allData?.location?.lang);
      formData.append("user", userId);
      formData.append("Compatible_Models", allData?.Compatible_Models);
      formData.append(
        "Prod_price",
        `${
          Number(allData?.Prod_price) + (Number(allData?.Prod_price) * 5) / 100
        }`
      );
      const res = await postData("product/add/", formData, true);
      if (res?.status) {
        setLoader(false);
        setFormDisabled(false);
        toast.success("Product added successfully Review By Admin !", {
          autoClose: 2000,
          closeButton: false,
        });
        dispatch(filterCategories([]));
        dispatch(filterSpec([]));
        dispatch(clearProdAddData());
        setTimeout(() => {
          navigate("/profile/ads/");
        }, 2000);
      } else {
        toast.error(res?.msg, { autoClose: 2000, closeButton: false });
      }
    } else {
      setBlur(true);
      setGetStart(!getStart);
      setLoader(false);
      setFormDisabled(false);
    }
  };
  const handleNavigate = () => {
    if (
      selectedPostType === "NEW" ||
      selectedPostType === "SPARE & ACCESSORIES" ||
      selectedPostType === "SERVICES"
    ) {
      navigate("/post/location/");
    } else {
      navigate("/post/pricing/");
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      {loader && <Loader />}
      {getStart ? (
        <GetStarted setGetStart={setGetStart} setBlur={setBlur} />
      ) : (
        ""
      )}
      {isBlur ? <BackgroundBlur /> : ""}
      <div onClick={handleNavigate} className={styles.postBack}>
        <img src={arrLeft} alt="..." />
        <span>Back</span>
      </div>

      <form className={styles.advtDataCont} onSubmit={handleSubmit}>
        {(() => {
          return getAddProdScreen3(
            selectedPostType,
            ManufacturingYear,
            dispatch,
            setManufacturingYear,
            prodPrice,
            setProdPrice,
            isValid,
            setIsValid,
            prodCondition,
            yearList,
            ref,
            price,
            setPrice
          );
        })()}
        <p>
          {`${
            selectedPostType === "SPARE & ACCESSORIES"
              ? "Accessories"
              : "Product"
          } Specifications`}
          <span className={styles.mandt}>*</span>
        </p>
        <div className={styles.advtDetails}>
          <div>
            <span>Brand/Company : </span>
            <input
              type="text"
              placeholder="Enter the name of Brand"
              name="brand"
              value={specifications.brand}
              onChange={handleChange}
            />
          </div>
          <div>
            <span>Model : </span>
            <input
              type="text"
              placeholder="Enter the model"
              name="model"
              value={specifications.model}
              onChange={handleChange}
            />
          </div>

          {selectedPostType === "SPARE & ACCESSORIES" ? (
            ""
          ) : (
            <React.Fragment>
              <div className={styles.advtRadio}>
                <span>Under Warranty :</span>
                <div>
                  <input
                    type="radio"
                    name="waranty"
                    value="1"
                    checked={specifications?.waranty === "1"}
                    onChange={handleChange}
                  />
                  <span>YES</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="waranty"
                    value="0"
                    checked={specifications?.waranty === "0"}
                    onChange={handleChange}
                  />
                  <span>NO</span>
                </div>
              </div>

              <div className={styles.advtRadio}>
                <span>Existing AMC/CMC :</span>
                <div>
                  <input
                    type="radio"
                    name="amc_cme"
                    value="1"
                    checked={specifications?.amc_cme === "1"}
                    onChange={handleChange}
                  />
                  <span>YES</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="amc_cme"
                    value="0"
                    checked={specifications?.amc_cme === "0"}
                    onChange={handleChange}
                  />
                  <span>NO</span>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className={styles.advtOther}>
            <span>
              {selectedPostType === "NEW"
                ? "Product Description"
                : "Other Details"}{" "}
              :
            </span>
            <textarea
              placeholder="Enter  the details"
              rows={20}
              cols={10}
              name="other_details"
              value={specifications?.other_details}
              onChange={handleChange}
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <input
            type="submit"
            className={styles.advtContinue}
            disabled={formDisabled}
            value="Submit"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

/*++++++++++++++++++++++++ Non Components ++++++++++++++++++++++++++++++++++*/

const getAddProdScreen2 = (selectedType, handleLocation) => {
  if (selectedType === "NEW") {
    return (
      <React.Fragment>
        <ToastContainer />
        <label htmlFor="lname">
          Equipment Location<span style={{ color: "red" }}>*</span>
        </label>
        <div htmlFor="lname" className={styles.currentLocation}>
          <img
            onClick={handleLocation}
            className={styles.locationPng}
            src={location}
            alt="..."
          />
          &nbsp;&nbsp;
          <span onClick={handleLocation}>Use Current Location</span>
        </div>
        <AddressModel />
      </React.Fragment>
    );
  } else if (selectedType === "PRE OWNED") {
    return (
      <React.Fragment>
        <ToastContainer />

        <label htmlFor="lname">
          Equipment Location<span style={{ color: "red" }}>*</span>
        </label>
        <div htmlFor="lname" className={styles.currentLocation}>
          <img
            onClick={handleLocation}
            className={styles.locationPng}
            src={location}
            alt="..."
          />
          &nbsp;&nbsp;
          <span onClick={handleLocation}>Use Current Location</span>
        </div>
        <AddressModel />
      </React.Fragment>
    );
  } else if (selectedType === "SPARE & ACCESSORIES") {
    return (
      <React.Fragment>
        <ToastContainer />

        <label htmlFor="lname">
          Equipment Location<span style={{ color: "red" }}>*</span>
        </label>
        <div htmlFor="lname" className={styles.currentLocation}>
          <img
            onClick={handleLocation}
            className={styles.locationPng}
            src={location}
            alt="..."
          />
          &nbsp;&nbsp;
          <span onClick={handleLocation}>Use Current Location</span>
        </div>
        <AddressModel />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ToastContainer />

        <label htmlFor="lname">
          Equipment Location<span style={{ color: "red" }}>*</span>
        </label>
        <div htmlFor="lname" className={styles.currentLocation}>
          <img
            onClick={handleLocation}
            className={styles.locationPng}
            src={location}
            alt="..."
          />
          &nbsp;&nbsp;
          <span onClick={handleLocation}>Use Current Location</span>
        </div>
        <AddressModel />
      </React.Fragment>
    );
  }
};

const getAddProdScreen3 = (
  selectedType,
  ManufacturingYear,
  dispatch,
  setManufacturingYear,
  prodPrice,
  setProdPrice,
  isValid,
  setIsValid,
  prodCondition,
  yearList,
  ref,
  price,
  setPrice
) => {
  const handleProdCondition = (event) => {
    const { name, value } = event.target;
    if (parseFloat(value.trim()) !== 0) {
      setPrice(value);
      dispatch(setEquipCondition({ ...prodCondition, name, value }));
    } else {
      setPrice("");
      dispatch(setEquipCondition({ [name]: "" }));
    }
  };

  const handleProdPrice = (event) => {
    const { value } = event.target;
    if (value.trim() !== "0") {
      setPrice(value);
      dispatch(setProdPrice(Number(value)));
    } else {
      setPrice("");
      dispatch(setProdPrice(""));
    }
    if (prodPrice === 0) {
      dispatch(setProdPrice(""));
    }
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const handleYear = (val) => {
    const isValidYear = /^\d{4}$/.test(val);
    const validYears = Array.from({ length: 24 }, (_, index) =>
      (new Date().getFullYear() - index).toString()
    );

    // Check if the input year is in the valid range
    if (isValidYear && validYears?.includes(val)) {
      dispatch(setManufacturingYear(val));
      setIsValid(true);
    } else {
      dispatch(setManufacturingYear(val));
      setIsValid(false);
    }
  };

  const handleYearChoose = (year) => {
    dispatch(setManufacturingYear(year));
    setIsValid(false);
  };

  if (selectedType === "NEW") {
    return (
      <React.Fragment>
        <ToastContainer />

        <div className={styles.totalPrice1}>
          <div className={styles.currSymbol1} style={{ marginTop: "35px" }}>
            <h3 className={styles.askprice}>
              Asking Price <span className={styles.mandt}>*</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={prodCondition?.price !== null ? prodCondition?.price : ""}
              onChange={handleProdCondition}
              name="price"
            />
            <p style={{ marginTop: "5px", marginBottom: "10px" }}>
              Platform fee including GST*
            </p>
          </div>
          <div className={styles.currSymbol1}>
            <h3 className={styles.askprice}>
              Platform Fee* <span className={styles.priceSpan}> (in INR*)</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              disabled="true"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={
                prodCondition?.price !== null
                  ? (Number(prodCondition?.price) * 5) / 100
                  : ""
              }
              onChange={handleProdCondition}
              name="price"
            />
          </div>
          <div className={styles.currSymbol1}>
            <h3 className={styles.askprice}>
              Total Price* <span className={styles.priceSpan}> (in INR*)</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              disabled="true"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={
                prodCondition?.price !== null
                  ? Number(prodCondition?.price) +
                    Number(prodCondition?.price * 5) / 100
                  : ""
              }
              onChange={handleProdCondition}
              name="price"
            />
          </div>
        </div>
        <div className={styles.radiosSpec}>
          <div>
            <input
              type="radio"
              value="1"
              name="negotiable"
              checked={prodCondition?.negotiable === "1"}
              onChange={handleProdCondition}
            />
            <label className={styles.rdt}>Negotiable</label>
          </div>
          <div>
            <input
              className={styles.rd1}
              type="radio"
              value="2"
              name="negotiable"
              checked={prodCondition?.negotiable === "2"}
              onChange={handleProdCondition}
            />
            <label className={styles.rdt}>Slightly Negotiable</label>
          </div>
          <div>
            <input
              className={styles.rd1}
              type="radio"
              value="3"
              name="negotiable"
              checked={prodCondition?.negotiable === "3"}
              onChange={handleProdCondition}
            />
            <label className={styles.rdt}>Non-Negotiable</label>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (selectedType === "PRE OWNED") {
    return (
      <>
        <ToastContainer />
        <div className={styles.specificYear}>
          <p>Manufacturing/ Purchase Year</p>
          <input
            type="text"
            readOnly
            name="purchase_year"
            placeholder="Select the year"
            value={ManufacturingYear}
            onClick={() => setIsValid(true)}
            autoComplete="off"
          />
          {isValid && (
            <div ref={ref} className={styles.yearListSh}>
              {yearList
                ?.sort((a, b) => b - a)
                ?.map((el) => {
                  return (
                    <p
                      className={styles.yearListDs}
                      onClick={() => handleYearChoose(el)}
                    >
                      {el}
                    </p>
                  );
                })}
            </div>
          )}
        </div>
      </>
    );
  } else if (selectedType === "SPARE & ACCESSORIES") {
    return (
      <React.Fragment>
        <ToastContainer />
        <div className={styles.totalPrice1}>
          <div className={styles.currSymbol1}>
            <h3 className={styles.askprice}>
              Accessories Price*{" "}
              <span className={styles.priceSpan}> (in INR*)</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={prodPrice}
              name="Prod_price"
              onChange={handleProdPrice}
            />
          </div>
          <div className={styles.currSymbol1}>
            <h3 className={styles.askprice}>
              Platform Fee* <span className={styles.priceSpan}> (in INR*)</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              disabled="true"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={(Number(prodPrice) * 5) / 100}
              name="Prod_price"
              onChange={handleProdPrice}
            />
          </div>
          <div className={styles.currSymbol1}>
            <h3 className={styles.askprice}>
              Total Price* <span className={styles.priceSpan}> (in INR*)</span>
            </h3>
            <i className="bi bi-currency-rupee"></i>
            <input
              className={styles.priceinput1}
              type="number"
              step="0.01"
              disabled="true"
              onKeyDown={blockInvalidChar}
              id={styles.rupee}
              value={Number(prodPrice) + (Number(prodPrice) * 5) / 100}
              name="Prod_price"
              onChange={handleProdPrice}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
};
