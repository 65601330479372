import React, { useEffect, useState } from "react";

function RequestServices() {
  const [showPopup, setShowPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allData, setAllData] = useState([]); // Store all fetched data
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [search, setSearch] = useState('');

  // Fetch services data
  const fetchServicesData = async () => {
    const url = 'https://mmq.mediquip.in/api/services/get/admin';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        console.log(response);
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      const filtered = data.filter(
        (item) => item.otherData && item.otherData.type === 'commanRequestService'
      );
      setAllData(filtered); // Store all data
      setFilteredData(filtered); // Initially set to all data
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  // const fetchServicesData = async () => {
  //   const url = "https://mmq.mediquip.in/api/services/get/admin";

  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       console.log(response);
  //       throw new Error(`Error: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     console.log("servicesDatadddd", data);
  //     // Set the fetched data
  //     const filtered = data.filter(item => item.otherData && item.otherData.type === "commanRequestService");
  //     console.log(filtered)
  //     setFilteredData(filtered);
  //     // Default filter to "AMC"
      
  //   } catch (error) {
  //     console.error("Error fetching services:", error);
  //   }
  // };

  useEffect(() => {
    fetchServicesData();
  }, []);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    
    if (value === '') {
      setFilteredData(allData); // Reset to all data if search is cleared
    } else {
      const filtered = allData.filter((item) =>
        item.serviceType?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  // Handle submit search (onSubmit)
  const handleFilterSearch = () => {
    const filtered = allData.filter((item) =>
      item.specialtyName?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // const filterData = (data) => {
  //   const filtered = servicesData.filter((item) => {
  //     return item.serviceType && item.serviceType.toLowerCase() === data.toLowerCase();
  //   });
  //   setFilteredData(filtered);

  //  }
  const onHandleShowPopup = (item, index) => {
    setSelectedIndex(item);
    setShowPopup(true);
  };
  return (
    <div>
      <div className=" flex flex-row w-full">
      <div className="flex justify-center my-4">
          <input
            type="search"
            className="p-2 pl-10 w-full appearance-none rounded text-sm text-gray-700"
            placeholder="Search by service type"
            value={search}
            onChange={onChangeSearch} // Real-time search (onSearch)
          />
        </div>
        <div className="flex justify-center pl-2 my-4">
          <button
            className="bg-[#219d8e] hover:bg-[#219d8e] text-white font-bold py-2 px-4 rounded-lg"
            onClick={handleFilterSearch} // Submit search (onSubmit)
          >
            Search
          </button>
        </div>
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Specility
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
              <th scope="col" className="px-6 py-3">
                Service Type
              </th>
              <th scope="col" className="px-6 py-3">
                Location
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={index}
                className={
                  "bg-gray-100 dark:bg-gray-700 border-b dark:bg-gray-800 dark:border-gray-700"
                }
              >
                <td className="px-6 py-4">{item.serviceType}</td>
                <td className="px-6 py-4">{item.specialityOrProviderName}</td>
                <td className="px-6 py-4">{item.description}</td>
                <td className="px-6 py-4">{item.amount}</td>
                <td className="px-6 py-4">{item.location2}</td>
                <td className="px-6 py-4">
                  <span
                    className="text-blue-500 hover:text-blue-700 text-white font-bold  rounded"
                    onClick={() => onHandleShowPopup(item, index)}
                  >
                    View
                  </span>
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-1/2 transform -translate-y-1/2 mx-auto p-5 border w-96 bg-white rounded-md shadow-lg">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">
                {selectedIndex?.serviceType}
              </h3>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setShowPopup(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <p className="mt-2">
              {selectedIndex?.description}
            </p>
            <p className="mt-2">
              {selectedIndex?.amount}
            </p>
            <p className="mt-2">
              {selectedIndex?.location2}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestServices;
