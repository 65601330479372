import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import { BlockToggle } from "./UsersList";
import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink, useNavigate } from "react-router-dom";
import common from "../assets/css/common.module.css";
import { Loader } from "../../components/Loader";
import { nextArow, swipetestleft } from "../../assets/images";
import { postData1 } from "../../services";
import { Pagination } from "../Common";

export const SparepareAssocirese = () => {
  const navigate = useNavigate();
  const [newAdslistData, setNewAdsListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };
  useEffect(() => {
    (async () => {
      const config = { post_type: 3, _page: currPage, _limit: 8 };
      const res = await postData1("admin/product_list/", config, true, true);
      setNewAdsListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  const handleView = (e, data) => {
    navigate("/admin/manageneweqip/viewuser/", { state: data });
  };

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>Spare and Accessories</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>PRODUCT ID</span>
          <span>OWNER</span>
          <span>EQUIP NAME</span>
          <span>CATEGORY</span>
          <span>SPECIALITY</span>
          <span>ASK PRICE</span>
          <span>BRAND</span>
          <span>WARRANTY</span>
          <span>CITY</span>
          <span>STATUS</span>
          <span>DETAIL</span>
        </div>

        {newAdslistData.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{data?.seller_firstname}</span>
              <span>{data?.equip_name}</span>
              <span>{data?.equipname_list[0]}</span>
              <span>{data?.speciality_name?.slice(0, 10)}</span>
              <span>{parseFloat(data?.asking_price).toFixed(2)}</span>
              <span>{data?.brand}</span>
              <span>{data?.warranty === 1 ? "Yes" : "No"}</span>
              <span>{data?.address?.slice(0, 20)}</span>
              <span>
                <span className={common.customStyle}>
                  {data?.product_status === 1
                    ? "OPEN"
                    : data?.product_status === 2
                    ? "LOCK"
                    : data?.product_status === 3
                    ? "Selled"
                    : data?.product_status === 4
                    ? "PENDING"
                    : ""}
                </span>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => handleView(e, data)}
              >
                View
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};

export const UsedEquipList = () => {
  const navigate = useNavigate();
  const [usedAdslistData, setUsedAdsListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  const handleView = (e, data) => {
    navigate("/admin/manageneweqip/viewuser/", { state: data });
  };

  useEffect(() => {
    (async () => {
      const config = { post_type: 1, _page: currPage, _limit: 8 };
      const res = await postData1("admin/product_list/", config, true, true);
      setUsedAdsListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>PREOWNED EQUIPMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>PRODUCT ID</span>
          <span>OWNER</span>
          <span>EQUIP NAME</span>
          <span>CATEGORY</span>
          <span>SPECIALITY</span>
          <span>ASK PRICE</span>
          <span>BRAND</span>
          <span>WARRANTY</span>
          <span>CITY</span>
          <span>STATUS</span>
          <span>DETAIL</span>
        </div>

        {usedAdslistData.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{data?.seller_firstname}</span>
              <span>{data?.equip_name}</span>
              <span>{data?.equipname_list[0]}</span>
              <span>{data?.speciality_name?.slice(0, 10)}</span>
              <span>{parseFloat(data?.asking_price).toFixed(2)}</span>
              <span>{data?.brand}</span>
              <span>{data?.warranty === 1 ? "Yes" : "No"}</span>
              <span>{data?.address?.slice(0, 20)}</span>
              <span>
                <span className={common.customStyle}>
                  {data?.product_status === 1
                    ? "OPEN"
                    : data?.product_status === 2
                    ? "LOCK"
                    : data?.product_status === 3
                    ? "Selled"
                    : data?.product_status === 4
                    ? "PENDING"
                    : ""}
                </span>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => handleView(e, data)}
              >
                View
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
