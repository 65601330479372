import React, { useCallback, useEffect, useRef, useState } from "react";
import { hero, } from "../assets/images/index";
import { Search } from "./Navigation";
import styles from "../assets/css/hero.module.css";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { plus_symbol, minus, m_search, downIcon } from "../assets/images/index";
import { FaMinus } from "react-icons/fa";
import {
  fetchCategories,
  fetchSpecialityName,
} from "../app/Slices/ProdAddSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
import { Loader as Loader2 } from "./Loader";
import { postData, postData1 } from "../services";
import useClickOutside from "../customHooks/useClickOutside";
import UseDebounce from "../utils/UseDebounce";
import { ToastContainer, toast } from "react-toastify";
import { useDebounce } from "@uidotdev/usehooks";

export const Hero = (props) => {
  const userId = localStorage.getItem("uid");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const heroStyle = {
    backgroundImage: `url(${hero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const handlePostValid = (event) => {
    // if (userId) {
    //   setLoader(true);
    //   (async () => {
    //     const res = await postData1("users/get_user_detail/", {}, true, false);
    //     if (res?.status_code === 200) {
    //       if (Object.keys(res?.data?.profile).length === 0) {
    //         toast.warning("Please complete your profile first!",{toastId: 'success1'});
    //       } else {
    //         navigate("/post/");
    //       }
    //     } else {
    //       toast.error("Something went wrong, please try after some time !");
    //     }
    //     setLoader(false);

    //   })();
    // } else {
    //   navigate("/user/login/");
    // }
    navigate("/post/");
  };
  return (
    <>
      <div id="heroBlur">
        {loader && <Loader2 />}
        <div  className={styles.heroContainer}>
          <Catogories />
          {/* <div className={styles.heroContent}>
            <p className={styles.heroHead}>
              Empower Your Health Journey Monitor Blood Pressure Like a Pro!
            </p>
            <p className={styles.heroDesc}>
              Precision measurements, advanced technology. Take control of your
              health with accurate blood pressure monitoring for informed
              decisions and optimal well-being
            </p>
            <NavLink to="/search/" className={styles.buyBtn}>
              Buy Product
            </NavLink>
            <div onClick={handlePostValid} className={styles.postAdvt}>
              Post Your Advertisement
            </div>
          </div> */}
          <Carousel />
        </div>
      </div>
    </>
  );
};

export const Catogories = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const filterCategoriesName = categories?.filter((el) => {
    return el.name !== "adminTest";
  });
  const [filterEquip, setFilterEquip] = useState("");
  const loader = useSelector((state) => state.addProd.prodAddData.isCatLoading);

  const handleChange = (e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    if (regex.test(e.target.value)) {
      setFilterEquip(e.target.value);
    } else {
      setFilterEquip("");
    }
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (filterEquip?.length > 0) {
      dispatch(fetchCategories(filterEquip));
    } else {
      dispatch(fetchCategories());
    }
  }, [filterEquip]);

  return (
    <div className={styles.catogories_container}>
      <div className={styles.upper_part}>
        {/* <span>FILTER</span> */}

        <Search
          placeholder="Search by category.."
          width={294}
          height={30}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          categorySearch={filterEquip}
          buttonWidth={48}
          buttonHeight={22}
          buttonFontSize={14}
          // isheader={true}
        />
        <p>EQUIPMENT</p>
      </div>
      <div className={styles.lower_part}>
        {loader && <Loader />}
        {filterCategoriesName?.length > 0
          ? filterCategoriesName
              ?.slice()
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((topLevelEquipment) => {
                return (
                  <CatItem
                    key={topLevelEquipment?.id}
                    pic={plus_symbol}
                    equipment={topLevelEquipment}
                    filterData={props.filterData}
                  />
                );
              })
          : !loader && (
              <div
                style={{
                  color: "#4E4D4D",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                EQUIPMENT NOT FOUND
              </div>
            )}
      </div>
    </div>
  );
};

const CatItem = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleNodeClick = () => {
    if (props?.equipment?.children?.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };
  const handleProdNameClick = (name) => {

    if (props?.equipment?.children?.length > 0) {
      setIsExpanded(!isExpanded);
      props.filterData(name);
      
    } else {
      console.log("ddddddddddd",name)

      // const encodedEquipName = encodeURIComponent(name);
      // navigate(`/search/search-items/${encodedEquipName}/`, {
      //   state: { cat: name },
      // });
    }
    
  };

  return (
    <div className={styles.cat_item}>
      <div>
        <div className={styles.cat_inner}>
          {props?.equipment?.children?.length > 0 ? (
            <img
              src={props?.pic}
              alt="..."
              onClick={handleNodeClick}
              className={styles.in_img}
            />
          ) : (
            <FaMinus />
          )}
          <span onClick={() => handleProdNameClick(props?.equipment?.name)}>
            {props?.equipment?.name}
          </span>
        </div>
        {isExpanded &&
          props?.equipment?.children?.map((child) => {
            return (
              <CatItem
                key={child?.id}
                pic={child?.children?.length > 0 ? props?.pic : <FaMinus />}
                equipment={child}
              />
            );
          })}
      </div>
    </div>
  );
};

export const MobileHero = () => {
  return (
    <div className={styles.MobileHero}>
      {/* <p style={{ textAlign: "center", marginTop:"40px" }}>WHAT ARE YOU LOOKING FOR?</p> */}
      <div className={styles.MobileHeroSearch}>
        <MobileSearch />
      </div>
    </div>
  );
};
export const MobileSearch = (props) => {
  const location = useLocation();
  const { toggle, setToggle, click } = props;
  const [togg, setTogg] = useState(toggle);
  const navigate = useNavigate();
  const [searchEqip, setSearchEquip] = useState("");
  const [loading, setLoading] = useState(false);
  const [getCategories, setCategories] = useState([]);
  const [filterCategories, setFilterCategoires] = useState([]);
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const handleEquipCategories = async () => {
    const formData = new FormData();
    formData.append("q", searchEqip);
    if (location?.pathname?.split("/")[1] === "speciality-search") {
      const res = await postData("product/speciality/lists/", formData);
      if (res.status) {
        setCategories(res.data);
      }
    } else {
      setCategories(categories);
    }
  };

  useDebounce(
    () => {
      setFilterCategoires(
        getCategories?.filter((d) => {
          return d?.name?.toLowerCase()?.includes(searchEqip?.toLowerCase());
        })
      );
    },
    [getCategories, searchEqip],
    2000
  );

  useEffect(() => {
    handleEquipCategories();
  }, [searchEqip]);

  const data = [
    "Ultrasound Machines",
    "CT Scanners",
    "IPL Machines",
    "MRI machines",
    "X-ray machines",
    "Alexandrite Lasers",
    "Optical coherence tomography",
    "Shock wave therapy machines",
    "Dialysis Machine",
  ];

  const ref = useRef(null);

  const clickOutSide = () => {
    setTogg(false);
    setSearchEquip("");
  };
  useClickOutside(ref, clickOutSide);

  const handleSearchItems = (item) => {
    const encodedEquipName = encodeURIComponent(item);
    navigate(`/search/search-items/${encodedEquipName}/`, {
      state: { cat: item },
    });
    setTogg(false);
    setSearchEquip("");
  };

  const handleScrollSpeed = (e) => {
    const scrollSpeed = 2;
    ref.current.scrollTop += e.deltay * scrollSpeed;
  };

  const handleCategoriesChange = (e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    if (regex.test(e.target.value)) {
      setSearchEquip(e.target.value);
    } else {
      setSearchEquip("");
    }
    setTogg(true);
  };

  return (
    <>
        {location.pathname === "/" && <SearchType placeholder="Find medical instrument.."/>}
    
      {/* <form
        style={{ position: "relative" }}
        ref={ref}
        className={togg ? styles.mobileSearch1 : styles.mobileSearch}
      >
        <div>
          <input
            ref={click}
            type="text"
            style={{
              border: !togg ? "2px solid #019C89" : "",
              width: togg ? "80%" : "",
            }}
            className={styles.inputField}
            value={searchEqip}
            onClick={() => {
              setTogg(true);
              // setToggle(true);
            }}
            onChange={(e) => handleCategoriesChange(e)}
            placeholder="Find medical equipments.."
          />
        </div>
        {togg ? (
          <button disabled={true} className={styles.searchBtn}>
            Search
          </button>
        ) : (
          <img src={m_search} alt="..." />
          // <div>
          //   <Category/>
          // </div>
        )}
        {togg && loading ? (
          <p className={styles.loadingView} />
        ) : togg ? (
          <p className={styles.loadingView1} />
        ) : (
          ""
        )}
        <div
          style={{ padding: "10px" }}
          ref={ref}
          onWheel={handleScrollSpeed}
          className={togg && searchEqip ? styles.searchCont1 : ""}
        >
          {searchEqip ? (
            filterCategories?.map((el) => {
              return (
                <>
                  <div
                    onClick={() => handleSearchItems(el?.name)}
                    className={el?.name ? styles.searchItem1 : ""}
                  >
                    <p className={styles.searchText}>
                      {`${searchEqip} in ${el?.name}`}
                    </p>
                  </div>
                </>
              );
            })
          ) : (
            <div className={styles.grid_container}>
              {togg
                ? data?.map((item, index) => (
                    <div
                      key={index}
                      className={styles.grid_item}
                      onClick={() => handleSearchItems(item)}
                    >
                      {item}
                    </div>
                  ))
                : ""}
            </div>
          )}
        </div>
      </form> */}
    </>
  );
};


export const SearchType = (props) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState(props?.categorySearch ?? "");
  const [categoriesList, setCategoriesList] = useState([]);
  const debouncedCategories = useDebounce(categories, 500);

  const handleClickOutSide = useCallback(() => {
    setCategories("");
  }, []);

  useClickOutside(ref, handleClickOutSide);

  const handleChange = useCallback((e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    
    if (regex.test(e.target.value)) {
      setCategories(e.target.value);
    } else {
      setCategories("");
    }
  }, []);

  const handleSubmit = useCallback((item) => {
    setCategories("");
    navigate(`/search/search-items/${encodeURIComponent(item)}/`, {
      state: { cat: item },
    });
  }, []);

  const handleSubmitCategory = useCallback(
    (event) => {
      event.preventDefault();
      
      if( categories === "") {
        return
      }
      
      navigate(`/search/search-items/${encodeURIComponent(categories)}/`, {
        state: { cat: categories },
      });
    },
    [categories]
  );

  const fetchCategoriesList = useCallback(async (categories) => {
    const formData = new FormData();
    formData.append("q", categories);

    try {
      const res = await postData("product/category/menulist/", formData);

      if (res?.data) {
        setCategoriesList(
          res.data.filter((d) => {
            return (
              d?.name !== "Other" &&
              d?.name?.toLowerCase().includes(categories?.toLowerCase())
            );
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setCategories(props?.categorySearch ?? "");
  }, [props?.categorySearch]);

  useEffect(() => {
    if (debouncedCategories) {
      fetchCategoriesList(debouncedCategories);
    }
  }, [debouncedCategories]);

  return (
    <div ref={ref}>
      <form
        // className={styles.searchForm}
        style={{ width: "100%",  display: "flex", flexDirection: "row", paddingBottom: "10px", paddingTop: "10px" }}
      >
        <input
          type="text"
          className={styles.searchInput}
          value={categories}
          placeholder={props.placeholder}
          onChange={props?.handleChange ?? handleChange}
          onKeyDown={props?.handleKeyPress}
          style={{
            height: "40px",
            width: "100%",
            fontSize: props.fontSize,
            borderRadius: "4px",
            padding: "0 10px",
            border: "none",
            outline: "none",
          }}
        />
        <button
          type="submit"
          className={styles.searchButton}
          style={{
             marginLeft: "-10px",
            height: "40px",
            paddingLeft: "2px",
            paddingRight: "2px",
            cursor: "pointer",
            borderRadius: "4px",
            backgroundColor: "white",
            padding: "0 10px",
            color: "gray",
            border: "none",
            outline: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleSubmitCategory}
        >
          {props.buttonValue || "Search"}
        </button>
      </form>

      {/* {categories && categoriesList?.length > 0 && (
        <div
          className={
            categoriesList?.length > 1
              ? styles.mainHeaderSearch
              : styles.mainHeaderSearch1
          }
          style={{
            width: props.width,
            height: props.height,
            display: props.showSearch == true ? "none" : "",
          }}
        >
          {categoriesList?.map((category) => {
            return (
              <p
                key={category?.id}
                onClick={() => handleSubmit(category?.name)}
                className={styles.subDropDOwn}
              >
                {category?.name}
              </p>
            );
          })}
        </div>
      )} */}
    </div>
  );
};



export const MobileCatogories = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  )
    ?.slice()
    ?.sort((a, b) => a.id > b.id);

  const filterCategoriesName = categories?.filter((el) => {
    return el.name !== "adminTest";
  });

  const speciality = useSelector(
    (state) => state.addProd.prodAddData.specialtiey_name
  )
    ?.slice()
    ?.sort((a, b) => a.id > b.id);

  const filterSPecialityName = speciality?.filter((el) => {
    return el.name !== "adminTest1" && el.name !== "admintTest2";
  });

  useEffect(() => {
    if (location?.pathname.split("/")[1] === "search") {
      dispatch(fetchCategories(""));
    } else if (location?.pathname.split("/")[1] === "speciality-search") {
      dispatch(fetchSpecialityName());
    }
  }, [location]);

  return (
    <div className={styles.mobileCatContainer}>
      {location?.pathname.split("/")[1] === "search"
        ? filterCategoriesName
            ?.slice()
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((values, index) => {
              return <CatgoriesDropDown key={index} data={values} />;
            })
        : filterSPecialityName
            ?.slice()
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((values, index) => {
              return <CatgoriesDropDown key={index} data={values} />;
            })}
    </div>
  );
};
const CatgoriesDropDown = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const subcat = props?.data?.children;
  const navigate = useNavigate();
  const handleList = () => {
    // navigate(
    //   `/search/search-items/${props?.data?.name.split("/").join("%")}/`,
    //   { state: { cat: props?.data?.name } }
    // );
  };
  return (
    <>
      {props.data.name ? (
        <div
          className={styles.catDrop}
          onMouseOver={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div onClick={handleList} className={styles.catTitle}>
            <span>{props?.data?.name}</span>
            {location?.pathname.split("/")[1] === "search" && (
              <img src={downIcon} alt="..." />
            )}
          </div>
          {location?.pathname.split("/")[1] === "search" && isOpen ? (
            <div className={styles.subCatogories}>
              {subcat?.map((value, index) => {
                return (
                  <NavLink
                    to={`/search/search-items/${encodeURIComponent(
                      value?.name
                    )}/`}
                    state={{ cat: value?.name }}
                    key={index}
                  >
                    {value?.name}
                  </NavLink>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <span>loading</span>
      )}
    </>
  );
};


export const Carousel = () => {
  const images = [
    hero,
    hero,
    hero,
  ];

  const content = [
    {
      heading: 'Empower Your Health Journey',
      description: 'Monitor Blood Pressure Like a Pro!',
      btnText: 'Buy Product',
      link: '/search/',
      advtText: 'Post Your Advertisement',
    },
    {
      heading: 'Track Your Fitness Goals',
      description: 'Get fit and stay healthy with our latest gadgets!',
      btnText: 'Explore Products',
      link: '/fitness/',
      advtText: 'Join Our Community',
    },
    {
      heading: 'Achieve Wellness with Ease',
      description: 'Discover wellness products tailored for you.',
      btnText: 'Shop Now',
      link: '/wellness/',
      advtText: 'Advertise With Us',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Automatically slide images every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // Change the image every 3 seconds

    return () => {
      clearInterval(interval); // Clear interval when component unmounts
    };
  }, [currentIndex]);

  // Handle Next button click
  const handleNext = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setIsAnimating(false);
    }, 500); // Duration of slide animation
  };

  // Handle Prev button click
  const handlePrev = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setIsAnimating(false);
    }, 500); // Duration of slide animation
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      {/* Image Container */}
      <div className="relative w-full h-full">
        {images.map((image, index) => (
          <div>
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={`absolute inset-0 w-full rounded-lg h-full object-cover transition-transform duration-500 ease-in-out ${
              index === currentIndex
                ? 'translate-x-0' // Currently visible slide
                : index === (currentIndex - 1 + images.length) % images.length
                ? '-translate-x-full' // Previous slide, moves out left
                : 'translate-x-full' // Next slide, comes from the right
            }`}
          />
          <div className={`absolute inset-0 w-full rounded-lg h-full object-cover transition-transform duration-500 ease-in-out ${
              index === currentIndex
                ? 'translate-x-0' // Currently visible slide
                : index === (currentIndex - 1 + images.length) % images.length
                ? '-translate-x-full' // Previous slide, moves out left
                : 'translate-x-full' // Next slide, comes from the right
            }`}>
        <div className="p-4 text-center">
          <h2 className="text-4xl text-white font-semibold mb-4">
            {content[currentIndex].heading}
          </h2>
          <p className="mb-4 text-white">{content[currentIndex].description}</p>
          <NavLink
            to={content[currentIndex].link}
            className="bg-[#019C89] hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg mb-2 inline-block"
          >
            {content[currentIndex].btnText}
          </NavLink>
          <div
           
            className="cursor-pointer text-blue-400 underline mt-2"
          >
            {content[currentIndex].advtText}
          </div>
        </div>
        </div>
          </div>
        ))}
      </div>

      {/* Prev Button */}
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2 rounded-full shadow-lg hover:bg-gray-700"
      >
        &#10094;
      </button>

      {/* Next Button */}
      <button
        onClick={handleNext}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2 rounded-full shadow-lg hover:bg-gray-700"
      >
        &#10095;
      </button>

      {/* Carousel Dots */}
      <div className="flex justify-center  space-x-2 mt-4">
        {images.map((_, index) => (
          <button
            key={index}
            className={`h-3 w-3 rounded-full ${
              index === currentIndex ? 'bg-gray-800' : 'bg-gray-400'
            }`}
            onClick={() => setCurrentIndex(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};



const Category = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className={styles.CategoryBtnDrop}>
      <select id="dropdown" value={selectedValue} onChange={handleChange}>
        <option value="">Category</option>
        <option value="option1">category 1</option>
        <option value="option2">category 2</option>
        <option value="option3">category 3</option>
      </select>
    </div>
  );
};

export default Category;

// non components Funtion
